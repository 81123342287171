import React from 'react';
import { useTranslation } from 'react-i18next';

const AdvancedSearchResultTag = ({ advancedSearchDataList, deleteAllFilters, deleteFilter, ns }) => {
    // console.log(advancedSearchDataList);
    const { t, i18n } = useTranslation(['common', ns]);
    // console.log(advancedSearchDataList);

    return (
        <>
            {advancedSearchDataList?.length > 0 && (
                <div className='d-flex flex-wrap col'>
                    <div
                        className='text-primary d-flex my-1 me-2'
                        style={{
                            height: '32px',
                            // width: '128px',
                            border: '1px solid #ECF4FD',
                            borderRadius: '4px',
                        }}
                    >
                        <div
                            className='px-2 '
                            style={{
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
                                <g id='Group_8537' data-name='Group 8537' transform='translate(-1424 -120)'>
                                    <path
                                        id='Path_1318'
                                        data-name='Path 1318'
                                        d='M11.949.283A.5.5,0,0,0,11.5,0H.5A.5.5,0,0,0,.049.286.506.506,0,0,0,.111.819L4.4,6.178V11.5a.5.5,0,0,0,.864.346l2.36-2.518a.506.506,0,0,0,.136-.346V6.131L11.893.814a.506.506,0,0,0,.056-.531'
                                        transform='translate(1426 122)'
                                        fill='#1278e8'
                                    />
                                    <rect
                                        id='Rectangle_3667'
                                        data-name='Rectangle 3667'
                                        width='16'
                                        height='16'
                                        transform='translate(1424 120)'
                                        fill='none'
                                    />
                                </g>
                            </svg>
                        </div>
                        <div
                            className='pe-2 w-100'
                            style={{
                                lineHeight: '32px',
                                textAlign: 'center',
                            }}
                        >
                            {/* {t('advancedSearch.advancedSearch')} */}
                            查詢條件
                        </div>
                        <button
                            type='button'
                            className='pe-2 d-flex align-items-center p-0 border-0 bg-transparent'
                            style={{ height: '32px' }}
                            onClick={(e) => {
                                deleteAllFilters();
                            }}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                id='icn_Clearsmall'
                                viewBox='0 0 16 16'
                                width='16'
                                height='16'
                            >
                                <defs>
                                    <clipPath id='clipPath-clearSmallGrey'>
                                        <rect
                                            id='Rectangle_3089'
                                            fill='#989898'
                                            width='12'
                                            height='12'
                                            data-name='Rectangle 3089'
                                        ></rect>
                                    </clipPath>
                                </defs>
                                <rect
                                    id='Rectangle_3326'
                                    fill='none'
                                    width='16'
                                    height='16'
                                    data-name='Rectangle 3326'
                                ></rect>
                                <g
                                    id='Group_6191'
                                    clipPath='url("#clip-path-clearSmallGrey")'
                                    transform='translate(2 2)'
                                    data-name='Group 6191'
                                >
                                    <path
                                        id='Path_1411'
                                        fill='#989898'
                                        transform='translate(-0.577 -0.577)'
                                        d='M 8.943 5.116 a 0.546 0.546 0 0 0 -0.744 -0.8 L 6.6 5.806 l -1.488 -1.6 a 0.546 0.546 0 0 0 -0.8 0.744 l 1.487 1.6 l -1.6 1.488 a 0.546 0.546 0 1 0 0.744 0.8 l 1.6 -1.488 l 1.487 1.6 a 0.546 0.546 0 1 0 0.8 -0.744 L 7.347 6.6 Z'
                                        data-name='Path 1411'
                                    ></path>
                                    <path
                                        id='Path_1412'
                                        fill='#989898'
                                        fillRule='evenodd'
                                        d='M 0 6 a 6 6 0 1 1 6 6 A 6 6 0 0 1 0 6 m 6 4.909 A 4.909 4.909 0 1 1 10.909 6 A 4.909 4.909 0 0 1 6 10.909'
                                        data-name='Path 1412'
                                    ></path>
                                </g>
                            </svg>
                        </button>
                    </div>
                    {advancedSearchDataList.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className='text-primary d-flex me-2 my-1'
                                style={{
                                    height: '32px',
                                    // width: '140px',
                                    border: '1px solid #ECF4FD',
                                    borderRadius: '4px',
                                    background: '#ECF4FD',
                                }}
                            >
                                <div
                                    className='ps-2 w-100'
                                    style={{
                                        lineHeight: '32px',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {/* {`${t(
                                        `${ns}:advancedSearch.${item.column}`
                                    )} ${item.tagSymbol} ${
                                        item.displaySearchValue
                                    }`} */}
                                    {`${item.column} ${item.tagSymbol} ${item.displaySearchValue}`}
                                </div>
                                <button
                                    type='button'
                                    className='px-2 d-flex align-items-center p-0 border-0 bg-transparent'
                                    style={{ height: '32px' }}
                                    onClick={(e) => {
                                        deleteFilter(index);
                                    }}
                                >
                                    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'>
                                        <path
                                            id='_Color'
                                            data-name=' ↳Color'
                                            d='M4,8A4,4,0,0,1,1.171,1.171,4,4,0,0,1,6.83,6.83,3.975,3.975,0,0,1,4,8ZM4,4.564H4L5.436,6,6,5.436,4.564,4,6,2.564,5.436,2,4,3.436,2.564,2,2,2.564,3.436,4,2,5.436,2.564,6,4,4.564Z'
                                            fill='#2e90f1'
                                        />
                                    </svg>
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default AdvancedSearchResultTag;
