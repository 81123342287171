import React from 'react';
import './loadingAnimate.scss';
import { ProgressBar } from 'react-bootstrap';

export const LoadingAnimate = ({ isLoading, progress }) => {
    return (
        <>
            <div
                className={`loadingWrap position-fixed w-100 h-100 align-items-center justify-content-center ${
                    isLoading ? 'd-flex' : 'd-none'
                }`}
            >
                <div className='loadingWrapper text-center' style={{ width: '50vw' }}>
                    <img src='./images/sysManage/loading.gif' alt='loading' className='mb-3' />
                    <ProgressBar now={progress} label={`${progress}%`} style={{ height: '1.5rem' }} />
                </div>
            </div>
        </>
    );
};
