import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const Thumbnail = styled.picture`
    display: inline-block;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: ${(props) => (props.active ? 'darkblue' : '')};
`;

const ThumbnailImage = styled.img`
    width: 200px;
    height: 200px;
    object-fit: cover;
`;

// 選擇商品附件的 Modal 組件 SelectAppendixModal

// 接受的 props:
// - `data`: 整個頁面的狀態數據
// - `setData`: 用於更新頁面狀態的函數
// - `modalOpen`: 控制 Modal 是否顯示的狀態
// - `setModalOpen`: 用於更新 Modal 顯示狀態的函數
// - `appendixList`: 商品附件列表的狀態
// - `setAppendixList`: 用於更新商品附件列表的函數
// - `selectedSpec`: 選擇的商品規格
// - `page`: 當前頁面的編號
// - `salesKitSetData`: 商品套組的狀態數據
// - `setSalesKitSetData`: 用於更新商品套組狀態的函數
// - `selectedSetGuid`: 選擇的商品套組的 Guid

// 在組件內部，首先初始化一個 `selectedAppendixGuidList` 的狀態，用於記錄選中的商品附件的 Guid。
// 根據 `appendixList` 中的商品附件列表，提取出已存在的 GUID，然後過濾 `salesKitAppendixStoreList`，排除已存在的商品附件，得到 `filteredSalesKitAppendixStoreList`。
// 使用 `map` 方法遍歷 `filteredSalesKitAppendixStoreList`，生成商品附件的縮略圖，點擊時觸發 `handleSelectAppendix` 函數更新 `selectedAppendixGuidList`。
// 最後，Modal 的底部包含確定和取消兩個按鈕，點擊確定時會觸發 `handleInsertAppendix` 函數，根據當前頁面的不同，執行相應的插入商品附件的操作，並關閉 Modal。

// 注意：具體的樣式和 Modal 結構實現未提供，需要參考具體的項目需求進行實現。

export default function SelectAppendixModal({ content, data, setData, modalOpen, setModalOpen }) {
    const { salesKit } = data;

    const [selectedAppendixGuidList, setSelectedAppendixGuidList] = useState([]);
    const salesKitAppendixStoreList = data?.salesKitAppendixStoreList;

    // 提取所有在 appendixList 中的 GUID
    // const existingGuidList = appendixList.map((item) => item.appendixGuid);

    // 過濾 salesKitAppendixStoreList，排除已存在於 existingGuidList 中的項目
    const filteredSalesKitAppendixStoreList = salesKitAppendixStoreList;
    // salesKitAppendixStoreList.filter(
    // (item) => !existingGuidList.includes(item.appendixGuid)
    // );

    // 現在 filteredSalesKitAppendixStoreList 就是不包含在 appendixList 中的項目的列表
    // console.log(filteredSalesKitAppendixStoreList);

    const handleSelectAppendix = (guid) => {
        setSelectedAppendixGuidList((prevList) => {
            return prevList.includes(guid) ? prevList.filter((item) => item !== guid) : [...prevList, guid];
        });
    };

    const handleSelectImage = () => {
        console.log(selectedAppendixGuidList);
        console.log(salesKitAppendixStoreList);

        const newArray = salesKitAppendixStoreList.filter((item) =>
            selectedAppendixGuidList.includes(item.appendixGuid)
        );

        console.log(newArray);

        const newSalesKit = { ...salesKit };

        newArray.forEach((el) => {
            const newImageHTML = `<div class="w-100"><img class="w-100" src=${el.appendixURL} alt=${el.appendixName}/></div>`;

            console.log(newImageHTML);
            newSalesKit.content = newSalesKit.content + newImageHTML;
        });

        setData({
            ...data,
            salesKit: newSalesKit,
        });

        setModalOpen(false);
    };

    return (
        <Modal className='selectAppendixModal modal' show={modalOpen} onHide={() => setModalOpen(false)} centered>
            <Modal.Header closeButton className='border-bottom'>
                <Modal.Title>從商品圖庫中選擇</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {filteredSalesKitAppendixStoreList?.map(({ appendixGuid, appendixURL }, index) => (
                    <Thumbnail
                        key={index}
                        active={selectedAppendixGuidList.includes(appendixGuid)}
                        onClick={() => handleSelectAppendix(appendixGuid)}
                    >
                        <source srcSet={appendixURL} />
                        <ThumbnailImage src={appendixURL} alt={appendixURL} />
                    </Thumbnail>
                ))}
            </Modal.Body>
            <Modal.Footer className='justify-content-center mx-4'>
                <button
                    type='button'
                    className='btn btn-primary text-white px-4 me-2'
                    // onClick={() => {
                    //     checkVerticalValue(editDrillItemData.vertical) &&
                    //         checkHorizontalValue(
                    //             editDrillItemData.horizontal
                    //         ) &&
                    //         checkHighSpeedValue(editDrillItemData.highSpeed) &&
                    //         handleEditDrillItemSubmit();
                    // }}
                    onClick={() => {
                        handleSelectImage();
                    }}
                >
                    確定
                </button>
                <button
                    type='button'
                    className='btnCancel btn btn-outline-primary px-4'
                    onClick={() => setModalOpen(false)}
                >
                    取消
                </button>
            </Modal.Footer>
        </Modal>
    );
}
