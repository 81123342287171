import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
`;

const Button = styled.button`
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #fff;
    width: 28px;
    text-align: center;
`;

const MinusButton = styled(Button)``;

const PlusButton = styled(Button)``;

const Count = styled.div`
    width: 56px;
`;

export default function Counter({ count, handleMinus, handlePlus, disabled = false }) {
    return (
        <Wrapper>
            <MinusButton onClick={handleMinus} disabled={disabled || count === 0}>
                -
            </MinusButton>
            <Count className='text-center border'>{count}</Count>
            <PlusButton onClick={handlePlus} disabled={disabled}>
                +
            </PlusButton>
        </Wrapper>
    );
}
