import { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import ChangeDeliveryLocationModal from '../Modals/ChangeDeliveryLocationModal';
import { t } from 'i18next';

const Wrapper = styled.div`
    font-size: 14px;
`;

const Title = styled.div`
    font-size: 16px;
    color: #989898;
`;

const EditLocation = styled.div`
    min-width: 48px;
    padding-left: 8px;
    color: #989898;
    text-decoration: underline;
    cursor: not-allowed;
`;

export default function OrderContentDetails({ order, t }) {
    const {
        orderNo,
        orderDate: orderDateUnformatted,
        recipientLastname,
        recipientFirstname,
        recipientCountryCode,
        recipientPhone,
        recipientStreet,
        note,
        firstname,
        email,
        lastname,
        countryCode,
        phone,
        status,
    } = order;

    // modals open
    const [changeDeliveryLocationModalOpen, setChangeDeliveryLocationModalOpen] = useState(false);

    const orderDate = dayjs(orderDateUnformatted).format('YYYY-MM-DD HH:mm:ss');

    return (
        <Wrapper>
            <section className='border-sm-0 border border-top-0'>
                <div className='p-4 row justify-content-between flex-column flex-md-row '>
                    <div className='col'>
                        <Title>{t('order_information')}</Title>
                        <div className='mt-4'>
                            <div>{`${t('order_number')} : ${orderNo}`}</div>
                            <div>{`${t('order_time')} : ${orderDate}`}</div>
                            <div>{`${t('recipient')} : ${t('mr_mrs', {
                                first: recipientFirstname,
                                last: recipientLastname,
                            })}`}</div>
                            <div>{`${t('contact_number')} : ${recipientCountryCode} ${recipientPhone}`}</div>
                            <div className='d-flex justify-content-start w-100'>
                                <div className=''>{`${t('shipping_address')} : ${recipientStreet}`}</div>
                                {(status === '0' || status === '1' || status === '2') && (
                                    <EditLocation
                                    // onClick={() => setChangeDeliveryLocationModalOpen(true)}
                                    >
                                        {t('edit')}
                                    </EditLocation>
                                )}
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div>{`${t('order_notes')} : ${note}`}</div>
                        </div>
                    </div>
                    <div className='col ps-md-4 mt-4 mt-md-0'>
                        <Title>{t('customer_information')}</Title>
                        <div className='mt-4'>
                            <div>{`${t('purchaser')} : ${t('mr_mrs', {
                                first: firstname,
                                last: lastname,
                            })}`}</div>
                            <div>{`${t('contact_number')} : ${countryCode} ${phone}`}</div>
                            <div>{`${t('order_email')} : ${email}`}</div>
                        </div>
                    </div>
                </div>
            </section>
            {changeDeliveryLocationModalOpen && (
                <ChangeDeliveryLocationModal
                    isOpen={changeDeliveryLocationModalOpen}
                    orderNo={order.orderNo}
                    handleClose={() => setChangeDeliveryLocationModalOpen(false)}
                />
            )}
        </Wrapper>
    );
}
