import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

const CancelSelect = styled.select`
    background-color: #f8f8f8;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    border: none;
    border-radius: 4px;
`;

const CancelOption = styled.option``;

const AddrInput = styled.input`
    height: 48px;
    width: 100%;
    border-radius: 4px;

    outline: 1px solid #1278e8;
`;

const RoundedButton = styled.button`
    border-radius: 24px;
    height: 48px;

    @media only screen and (max-width: 1020px) {
        width: 100%;
    }
`;

export default function ChangeDeliveryLocationModal({ isOpen, handleClose, orderNo }) {
    const [cancelReq, setCancelReq] = useState({
        reason: '',
        desc: '',
    });

    console.log(cancelReq);
    return (
        <div key='advancedSearchModal'>
            <Modal isOpen={isOpen} centered={true} style={{ maxWidth: '496px' }}>
                <ModalHeader className='position-relative pt-4 pb-2 ps-4 text-start row'>
                    修改配送地址
                    <button
                        style={{ top: '12px', right: '12px' }}
                        type='button'
                        className='close position-absolute btn'
                        onClick={handleClose}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            id='icn_Clear'
                            viewBox='0 0 16 16'
                            width='16'
                            height='16'
                        >
                            <defs>
                                <clipPath id='clipPath-clear22'>
                                    <rect
                                        id='Rectangle_3088'
                                        fill='#686868'
                                        width='10'
                                        height='10'
                                        data-name='Rectangle 3088'
                                    />
                                </clipPath>
                            </defs>
                            <rect id='Rectangle_3086' fill='none' width='16' height='16' data-name='Rectangle 3086' />
                            <g id='Group_6190' transform='translate(3 3)' data-name='Group 6190'>
                                <g id='Group_6188' clipPath='url("#clipPath")' data-name='Group 6188'>
                                    <path
                                        id='Path_1410'
                                        fill='#686868'
                                        transform='translate(0)'
                                        d='M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z'
                                        data-name='Path 1410'
                                    />
                                </g>
                            </g>
                        </svg>
                    </button>
                </ModalHeader>

                <ModalBody className='px-4 pt-3 pb-0'>
                    <div className='mt-2'>
                        <div className='mb-2'>地址</div>
                        <AddrInput
                            onChange={(e) =>
                                setCancelReq({
                                    ...cancelReq,
                                    desc: e.target.value,
                                })
                            }
                            value={cancelReq?.desc}
                        ></AddrInput>
                        <p>*修改地址僅限待出貨前*每筆僅限1次。</p>
                    </div>
                </ModalBody>

                <ModalFooter className='modal-footer justify-content-center border-0 px-4 pt-5 pb-5 pb-0'>
                    <RoundedButton className='btn btn-primary w-100 text-white'>確定</RoundedButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}
