import React from 'react';
import { Modal } from 'react-bootstrap';

function InsertDrillItemModal({
    insertDrillModalOpen,
    setInsertDrillModalOpen,
    newDrillItemData,
    setNewDrillItemData,
    drillCategoryItemList,
    spinNameTable,
    handleInsertDrillItemSubmit,
    // handleSpinPowerChange,
    // handleHighSpeedChange,
    // handleSpinChange,
}) {
    console.log(newDrillItemData);
    const checkVerticalValue = (val) => {
        if ((val / 10) % 0.5 !== 0 || val > 400 || val < -400) {
            alert('请输入0.5的倍数,數值介於-40至40之間');
            setNewDrillItemData({ ...newDrillItemData, vertical: 0 });

            return false;
        }

        return true;
    };

    const checkHorizontalValue = (val) => {
        if ((val / 10) % 0.5 !== 0 || val > 300 || val < -300) {
            alert('请输入0.5的倍数,數值介於-30至30之間');
            setNewDrillItemData({ ...newDrillItemData, horizontal: 0 });

            return false;
        }

        return true;
    };

    const checkDelayValue = (val) => {
        if (val > 3 || val < 0 || !val) {
            alert('请输入數值介於0至3.0之間');
            setNewDrillItemData({ ...newDrillItemData, delay: 0 });

            return false;
        }

        return true;
    };

    const checkHighSpeedValue = (val) => {
        const parsedValue = parseInt(val, 10);

        if (isNaN(parsedValue) || val > 80 || val < 10 || !val) {
            alert('出球速度 : 请输入5的倍数,數值介於10至80之間');
            setNewDrillItemData({ ...newDrillItemData, highSpeed: '' });

            return false;
        }

        return true;
    };

    const calculateLowSpeed = (
        highSpeedWheel = newDrillItemData.highSpeedWheel,
        highSpeed = newDrillItemData.highSpeed,
        powerBLDC1 = newDrillItemData.powerBLDC1,
        powerBLDC2 = newDrillItemData.powerBLDC2,
        spinPower = newDrillItemData.spinPower,
        spin = newDrillItemData.spin
    ) => {
        console.log(newDrillItemData);

        // 計算最高旋球強度
        const maxSpinPower = Math.floor((highSpeed - 10) / 2 + 1);

        console.log(maxSpinPower);
        console.log(spinPower);
        // 計算低速輪

        let correctSpin = 0;

        // 如果本來是無旋球
        if (spinPower === 0) {
            correctSpin = maxSpinPower;
            spinPower = maxSpinPower;

            // 其他
        } else {
            correctSpin = spinPower > maxSpinPower ? maxSpinPower : spinPower;
        }

        console.log(correctSpin);

        const lowSpeed = highSpeed - (2 * (correctSpin - 1) + 10);

        setNewDrillItemData({
            ...newDrillItemData,
            highSpeedWheel: highSpeedWheel,
            highSpeed: highSpeed,
            lowSpeed: lowSpeed,
            powerBLDC1: powerBLDC1,
            powerBLDC2: powerBLDC2,
            spinPower: spinPower,
            maxSpinPower: maxSpinPower,
            spin: spin,
        });
    };

    // 改變 spin power, 帶動 低速輪改變
    const handleSpinPowerChange = (val) => {
        calculateLowSpeed(undefined, undefined, undefined, undefined, val, undefined);
    };

    const handleSpinChange = (val) => {
        calculateLowSpeed(undefined, undefined, undefined, undefined, undefined, val);
    };

    const handleHighSpeedChange = (val) => {
        calculateLowSpeed(undefined, val, undefined, undefined, undefined, undefined);
    };
    return (
        <Modal
            className='editDrillItemModal modal'
            show={insertDrillModalOpen}
            onHide={() => setInsertDrillModalOpen(false)}
            centered
        >
            <Modal.Header closeButton className='border-bottom'>
                <Modal.Title>新增球路</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row g-3 align-items-end'>
                    <div className='col-6'>
                        <label htmlFor='fullname' className='fontSize75Rem mb-2'>
                            俯仰角度
                        </label>
                        <select
                            className='form-control mb-3 w-100'
                            name='verticalDirection'
                            id='verticalDirection'
                            value={newDrillItemData.verticalDirection || 0}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    verticalDirection: e.target.value,
                                })
                            }
                        >
                            <option value={1}>上</option>
                            <option value={-1}>下</option>
                        </select>
                    </div>
                    <div className='col-6'>
                        <input
                            id='vertical'
                            name='vertical'
                            className='form-control mb-3'
                            type='number'
                            value={newDrillItemData.vertical / 10}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    vertical: e.target.value * 10,
                                })
                            }
                            onBlur={(e) => checkVerticalValue(e.target.value * 10)}
                        />
                    </div>
                    {/* <div className="col-6">
                        <select
                            className="form-control mb-3 w-100"
                            name="vertical"
                            id="vertical"
                            value={newDrillItemData.vertical || 0}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    vertical: e.target.value,
                                })
                            }
                        >
                            <option value={0}>0</option>
                            <option value={50}>5</option>
                            <option value={100}>10</option>
                            <option value={150}>15</option>
                            <option value={200}>20</option>
                            <option value={250}>25</option>
                            <option value={300}>30</option>
                            <option value={350}>35</option>
                            <option value={400}>40</option>
                        </select>
                    </div> */}
                </div>
                <div className='row g-3 align-items-end'>
                    <div className='col-6'>
                        <label htmlFor='fullname' className='fontSize75Rem mb-2'>
                            水平角度
                        </label>
                        <select
                            className='form-control mb-3 w-100'
                            name='horizontalDirection'
                            id='horizontalDirection'
                            value={newDrillItemData.horizontalDirection}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    horizontalDirection: e.target.value,
                                })
                            }
                        >
                            <option value={-1}>左</option>
                            <option value={1}>右</option>
                        </select>
                    </div>
                    <div className='col-6'>
                        <input
                            id='vertical'
                            name='vertical'
                            className='form-control mb-3'
                            type='number'
                            value={newDrillItemData.horizontal / 10 || 0}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    horizontal: e.target.value * 10,
                                })
                            }
                            onBlur={(e) => checkVerticalValue(e.target.value * 10)}
                        />
                    </div>
                    {/* <select
                            className="form-control mb-3 w-100"
                            name="horizontal"
                            id="horizontal"
                            value={newDrillItemData.horizontal || 0}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    horizontal: e.target.value,
                                })
                            }
                        >
                            <option value={0}>0</option>
                            <option value={50}>5</option>
                            <option value={100}>10</option>
                            <option value={150}>15</option>
                            <option value={200}>20</option>
                            <option value={250}>25</option>
                            <option value={300}>30</option>
                        </select> */}
                </div>
                <div className='row g-3'>
                    <div className='col-12'>
                        <label htmlFor='spinPower' className='fontSize75Rem mb-2'>
                            出球速度
                        </label>
                        <input
                            id='highSpeed'
                            name='highSpeed'
                            className='form-control mb-3'
                            type='number'
                            value={newDrillItemData.highSpeed || ''}
                            onChange={(e) => {
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    highSpeed: e.target.value,
                                });
                                // handleHighSpeedChange(e.target.value);
                            }}
                            onBlur={(e) => {
                                handleHighSpeedChange(e.target.value);
                                checkHighSpeedValue(e.target.value);
                            }}
                        />
                        <label htmlFor='spinPower' className='fontSize75Rem mb-2'>
                            旋球強度
                        </label>
                        <select
                            className='form-control mb-3 w-100'
                            name='spinPower'
                            id='spinPower'
                            value={
                                newDrillItemData.spin === 'noSpin'
                                    ? 'noSpin'
                                    : newDrillItemData.spinPower > newDrillItemData.maxSpinPower
                                      ? newDrillItemData.maxSpinPower
                                      : newDrillItemData.spinPower
                            }
                            disabled={newDrillItemData.spin === 'noSpin'}
                            onChange={(e) => {
                                handleSpinPowerChange(e.target.value);
                            }}
                        >
                            {Array.from(
                                {
                                    length: newDrillItemData.maxSpinPower,
                                },
                                (_, index) => (
                                    <option key={`spinPower${index + 1}`} value={index + 1}>
                                        {index + 1}
                                    </option>
                                )
                            ).reverse()}
                            {!newDrillItemData.spin === 'noSpin' && (
                                <option key='spinPower0' value='noSpin'>
                                    不旋球
                                </option>
                            )}
                        </select>
                        <label htmlFor='fullname' className='fontSize75Rem mb-2'>
                            旋球角度
                        </label>
                        <select
                            className='form-control mb-3 w-100'
                            name='spin'
                            id='spin'
                            value={newDrillItemData.spin || 0}
                            onChange={(e) => {
                                handleSpinChange(e.target.value);
                            }}
                        >
                            {Array.from({ length: 16 }, (_, index) => (
                                <option key={`newSpin${index}`} value={index * 225}>
                                    {spinNameTable[index]}
                                </option>
                            ))}
                            {drillCategoryItemList.map((el) => (
                                <option value={el.categoryItemGuid} key={el.categoryItemGuid} id={el.categoryItemGuid}>
                                    {`${el.name_ZH}`}
                                </option>
                            ))}
                            <option value='noSpin'>不旋球</option>
                        </select>
                        <label htmlFor='fullname' className='fontSize75Rem mb-2'>
                            出球延遲(秒)
                        </label>
                        <input
                            id='newDelay'
                            name='newDelay'
                            className='form-control mb-3'
                            type='number'
                            value={newDrillItemData.delayUI || ''}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    delayUI: e.target.value,
                                })
                            }
                            onBlur={(e) => checkDelayValue(e.target.value)}
                        />
                        {/* <select
                            className="form-control mb-3 w-100"
                            name="spin"
                            id="spin"
                            value={newDrillItemData.delay || 0}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    delay: e.target.value,
                                })
                            }
                        >
                            <option value={0}>0</option>
                            <option value={5}>0.5</option>
                            <option value={10}>1</option>
                            <option value={15}>1.5</option>
                            <option value={20}>2</option>
                            <option value={25}>2.5</option>
                            <option value={30}>3</option>
                        </select> */}
                    </div>
                </div>
                <div className='row g-3 align-items-end'>
                    <div className='col-6'>
                        <label htmlFor='fullname' className='fontSize75Rem mb-2'>
                            高速輪
                        </label>
                        <input
                            id='horizontal'
                            name='horizontal'
                            className='form-control mb-3'
                            type='number'
                            value={newDrillItemData.highSpeed}
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    horizontal: e.target.value,
                                })
                            }
                            disabled
                        />
                    </div>
                    <div className='col-6'>
                        <label htmlFor='fullname' className='fontSize75Rem mb-2'>
                            低速輪
                        </label>
                        <input
                            id='horizontal'
                            name='horizontal'
                            className='form-control mb-3'
                            type='number'
                            value={
                                newDrillItemData.spin === 'noSpin'
                                    ? newDrillItemData.highSpeed
                                    : newDrillItemData.lowSpeed
                            }
                            onChange={(e) =>
                                setNewDrillItemData({
                                    ...newDrillItemData,
                                    horizontal: e.target.value,
                                })
                            }
                            disabled
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center mx-4'>
                <button
                    type='button'
                    className='btn btn-primary text-white px-4 me-2'
                    onClick={() => {
                        checkVerticalValue(newDrillItemData.vertical) &&
                            checkHorizontalValue(newDrillItemData.horizontal) &&
                            checkHighSpeedValue(newDrillItemData.highSpeed) &&
                            handleInsertDrillItemSubmit();
                    }}
                >
                    確定
                </button>
                <button
                    type='button'
                    className='btnCancel btn btn-outline-primary px-4'
                    onClick={() => setInsertDrillModalOpen(false)}
                >
                    取消
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default InsertDrillItemModal;
