import { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as AddAppendix } from '../../../assets/images/icn_AddAppendix.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/copied-icon.svg';

// #region styled-components

const AppendixCard = styled.div`
    height: 200px;
    width: 224px;
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: ${(props) =>
        props.view === 'details' ? '1px solid #EFF2F5' : props.activeCard ? '1px solid #1278E8' : '1px solid #EFF2F5'};
    overflow: hidden;
    cursor: pointer;
    overflow: hidden;
    cursor: pointer;
    position: relative;
`;

//#endregion

// 這個 React 組件用於管理附件的卡片集合，具有以下主要功能和特點：

// 1. `Appendix` 組件接收 `view`、`data` 和 `setData` 三個屬性，分別代表視圖模式、附件數據和更新附件數據的函數。
// 2. 附件卡片的樣式使用了 styled-components 進行定義，樣式包括高度、寬度、邊框等，並根據視圖模式和卡片選中狀態進行區分。
// 3. 附件卡片點擊事件、雙擊下載事件、卡片內容顯示等交互功能都在組件中實現。
// 4. 附件數據的管理包括文件上傳、附件名稱編輯、附件刪除等功能，並且附件數據的變更會觸發組件重新渲染。
// 5. 使用 useRef 來記錄被選中的卡片和文件上傳的 input，使用 useState 來維護一些組件內的狀態。
// 6. 透過 handleFileUpload 函數實現文件上傳功能，並根據上傳的文件更新附件列表和文件列表的狀態。
// 7. handleSelectTab 函數用於更新選中的附件分類。
// 8. handleDoubleClickCard 函數實現雙擊下載功能，點擊卡片兩次會觸發該事件。
// 9. handleCardImage 函數根據附件的文件類型選擇合適的顯示方式，如圖片、文檔類型等。
// 10. handleChangeAppendixName 函數用於編輯附件名稱。
// 11. handleDeleteAppendix 函數用於刪除附件卡片，會更新附件列表的狀態。
// 12. 整體而言，這個組件提供了豐富的附件管理功能，包括上傳、編輯、刪除等，以及直觀的附件展示方式。

export default function Appendix({ view = 'details', data, setData }) {
    // 被選中的附件分類
    const [activeTab, setActiveTab] = useState('');
    // 記住哪個 file 被按的 ref
    const fileInputRef = useRef(null);
    // 記住哪個 card 被按
    const [activeCard, setActiveCard] = useState('');

    // 上傳文件
    function handleFileUpload(e) {
        const target = e.target.files;
        if (target.length) {
            // 文件本體
            const file = target[0];
            // 文件路徑
            const blobURL = URL.createObjectURL(file);
            // 文件名稱
            let fileName = file.name ? file.name.split('.')[0] : 'file';
            // 文件類型
            const fileType = file.name ? file.name.split('.').pop() : 'file';

            // handle the uploaded file here

            const newAppendixList = [
                ...data.appendixList,
                {
                    action: 'insert',
                    appendixName: fileName,
                    appendixURL: blobURL,
                    appendixType: fileType,
                    displayOrder: data?.appendixList.length + 1,
                    appendixFile: file,
                },
            ];
            const newFileList = [...(data.fileList || []), file];

            setData({
                ...data,
                appendixList: newAppendixList,
                fileList: newFileList,
            });
        }
    }

    /// ***: 為什麼一定要用 handleSelectTab 要改變 activeTab?
    /// ***: 為什麼不用傳 selectedTab 參數到 handleSelectTab, handleSelectTab 也知道 selectedTab 是什麼?
    /// AI 的解答如下:
    // selectedTab 是由 activeKey 屬性提供的而 onSelect 屬性則是用於在選擇標籤時觸發事件，從而更新 activeKey 狀態。
    // handleSelectTab 函數是作為 onSelect 屬性的回調函數，而 selectedTab 參數則是由 handleSelectTab 函數自動接收的，代表了選擇的標籤的值。
    // 因此，您不能直接在 selectedTab 屬性中使用 selectedTab 變量，因為這個變量是由 handleSelectTab 函數在用戶選擇標籤時接收的，並且是動態的。
    // 如果您需要將 activeKey 狀態更新為用戶選擇的標籤值，請繼續使用 handleSelectTab 函數，並在該函數中調用 setActiveTab 函數更新狀態
    const handleSelectTab = (selectedTab) => {
        setActiveTab(selectedTab);
    };

    // 雙擊下載
    // todo: 沒有彈出選擇儲存位置, 之後再處理
    const handleDoubleClickCard = (e, el, index) => {
        if (e.target.tagName.toLowerCase() !== 'input') {
            const fileName = el.appendixName;
            const file = data?.appendixList[index].appendixURL;

            const link = document.createElement('a');
            link.href = file;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // 顯示卡片
    const handleCardImage = (info, data) => {
        const { attachmentGuid, appendixName, appendixURL, appendixType } = info;
        const { appendixList } = data;
        const fileType = appendixType?.toUpperCase() || appendixURL.split('.').pop().toUpperCase();

        if (['PNG', 'JPG', 'JPGE', 'GIF'].some((value) => fileType === value)) {
            return <img className='h-100 w-100' src={appendixURL} />;
        } else if (['DOC', 'DOCX'].some((value) => fileType === value)) {
            return <h6 className='m-0 px-2 py-2 bg-primary rounded text-white'>{fileType}</h6>;
        } else if (['PDF', 'PPT', 'PPTX'].some((value) => fileType === value)) {
            return <h6 className='m-0 px-2 py-2 bg-danger rounded text-white'>{fileType}</h6>;
        } else if (['XLS', 'XLSX'].some((value) => fileType === value)) {
            return <h6 className='m-0 px-2 py-2 bg-success rounded text-white'>{fileType}</h6>;
        } else {
            return <h6 className='m-0 px-2 py-2 bg-secondary rounded text-white'>{fileType}</h6>;
        }
    };

    // const getattachmentGuidList = () => {
    //     axiosattachmentGuidList(
    //         {
    //             url: `api/logistics/Lgspurchaseorder/getcreatedatapage3`,
    //         },
    //         (res) => {
    //             setattachmentGuides(res.lgsattachmentGuidList);
    //             res.lgsattachmentGuidList.length &&
    //                 setActiveTab(
    //                     res.lgsattachmentGuidList[0].attachmentGuidGuid
    //                 );
    //         }
    //     );
    // };

    // todo: 詳細講講 composition
    // 用於記錄 composition 狀態用
    let isOnComposition = false;

    const handleComposition = (e, index) => {
        const val = e.target.value;

        if (e.type === 'compositionend') {
            //composition結束，代表中文輸入完成
            console.log('compositionend');
            isOnComposition = false;
            handleChangeAppendixName(val, index);
        } else {
            //composition進行中，代表正在輸入中文
            isOnComposition = true;
        }
    };

    // 改名字
    const handleChangeAppendixName = (val, index) => {
        const newAppendixList = [...data.appendixList];
        newAppendixList[index].appendixName = val;
        if (newAppendixList[index].action === 'origin') {
            newAppendixList[index].action = 'update';
        }

        setData({
            ...data,
            appendixList: newAppendixList,
        });
    };

    // 刪卡片
    const handleDeleteAppendix = (appendixIndex) => {
        // 將要刪除的 item 把 action 改成 delete, 以供 UI render 和 之後後端處理資料

        console.log(appendixIndex);
        const newAppendixList = [];
        data?.appendixList.map((el, index) => {
            if (appendixIndex === index) {
                console.log(el);
                if (el.action === 'origin') {
                    newAppendixList.push({ ...el, action: 'delete' });
                }
            } else {
                newAppendixList.push(el);
            }
        });

        // 因為 data?.appendixList 要和 data?.appendixList 保持一致性, 所以要改用 data?.appendixList 的 action 是不是 delete 來決定要不要 render 此 item
        // 要刪除的 item 不會被放到 newAppendixData 裡
        const newAppendixData = [];
        data?.appendixList.map((el, index) => {
            if (appendixIndex === index) {
                console.log(el);
                if (data?.appendixList[index].action === 'origin') {
                    newAppendixData.push(el);
                }
            } else {
                newAppendixData.push(el);
            }
        });

        // 要刪除的 item 不會被放到 newFileList 裡
        // const newFileList = [];
        // data.fileList.map((el, index) => {
        //     if (appendixIndex === index) {
        //         console.log(el);
        //         if (data.appendixList[index].action === 'origin') {
        //             newFileList.push(el);
        //         }
        //     } else {
        //         newFileList.push(el);
        //     }
        // });

        const targetItem = [...data.appendixList].filter((_, index) => index === appendixIndex);

        setData({
            ...data,
            appendixList: newAppendixList,
            // fileList: newFileList,
        });
    };

    // console.log(data.appendixList)
    // console.log(data.fileList)

    return (
        <>
            <div className='px-4 py-6 d-flex flex-wrap'>
                {data?.appendixList?.map((el, appendixIndex) => {
                    return (
                        el.action !== 'delete' && (
                            <AppendixCard
                                key={el.appendixName + appendixIndex}
                                id={el.appendixName + appendixIndex}
                                onClick={() => setActiveCard(el.appendixURL + appendixIndex)}
                                {...(view !== 'create' && {
                                    onDoubleClick: (e) => {
                                        // console.log(
                                        //     el,
                                        //     appendixIndex
                                        // );
                                        handleDoubleClickCard(e, el, appendixIndex);
                                    },
                                })}
                                activeCard={el.appendixURL + appendixIndex === activeCard}
                                view={view}
                            >
                                {el.appendixURL + appendixIndex === activeCard && (
                                    <>
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '12px',
                                                right: '12px',
                                            }}
                                            onClick={() => {
                                                handleDeleteAppendix(appendixIndex);
                                            }}
                                        >
                                            <svg
                                                id='Group_6556'
                                                dataname='Group 6556'
                                                xmlns='http://www.w3.org/2000/svg'
                                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                                width='16'
                                                height='16'
                                                viewBox='0 0 16 16'
                                            >
                                                <defs>
                                                    <clipPath id='clip-path-times1811DDDDDDD'>
                                                        <rect
                                                            id='Rectangle_3429'
                                                            dataname='Rectangle 3429'
                                                            width='16'
                                                            height='16'
                                                            fill='#ea4132'
                                                        ></rect>
                                                    </clipPath>
                                                </defs>
                                                <g
                                                    id='Group_6556-2'
                                                    dataname='Group 6556'
                                                    clipPath='url(#clip-pathtimes1811DDDDDDD)'
                                                >
                                                    <path
                                                        id='Path_1484'
                                                        dataname='Path 1484'
                                                        d='M6.785,12.011h0a5.226,5.226,0,1,1,5.226-5.226,5.226,5.226,0,0,1-5.226,5.226'
                                                        transform='translate(1.215 1.216)'
                                                        fill='#fff'
                                                    ></path>
                                                    <path
                                                        id='Path_1485'
                                                        dataname='Path 1485'
                                                        d='M8,16a7.995,7.995,0,1,1,5.657-2.34A8,8,0,0,1,8,16M8,9.126H8L10.868,12,12,10.87,9.124,8,12,5.128,10.868,4,8,6.872,5.127,4,4,5.128,6.869,8,4,10.87,5.127,12Z'
                                                        transform='translate(0.001 0.001)'
                                                        fill='#ea4132'
                                                    ></path>
                                                </g>
                                            </svg>
                                        </span>
                                        {/* <span
                                                style={{
                                                    position: 'absolute',
                                                    top: '12px',
                                                    left: '12px',
                                                    height: '16px',
                                                    width: '16px',
                                                }}
                                                onClick={() => {
                                                    alert('COPIED !');
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    shape-rendering="geometricPrecision"
                                                    text-rendering="geometricPrecision"
                                                    image-rendering="optimizeQuality"
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    viewBox="0 0 438 511.52"
                                                    width="16"
                                                    height="16"
                                                >
                                                    <path
                                                        fill-rule="nonzero"
                                                        d="M141.44 0h172.68c4.71 0 8.91 2.27 11.54 5.77L434.11 123.1a14.37 14.37 0 0 1 3.81 9.75l.08 251.18c0 17.62-7.25 33.69-18.9 45.36l-.07.07c-11.67 11.64-27.73 18.87-45.33 18.87h-20.06c-.3 17.24-7.48 32.9-18.88 44.29-11.66 11.66-27.75 18.9-45.42 18.9H64.3c-17.67 0-33.76-7.24-45.41-18.9C7.24 480.98 0 464.9 0 447.22V135.87c0-17.68 7.23-33.78 18.88-45.42C30.52 78.8 46.62 71.57 64.3 71.57h12.84V64.3c0-17.68 7.23-33.78 18.88-45.42C107.66 7.23 123.76 0 141.44 0zm30.53 250.96c-7.97 0-14.43-6.47-14.43-14.44 0-7.96 6.46-14.43 14.43-14.43h171.2c7.97 0 14.44 6.47 14.44 14.43 0 7.97-6.47 14.44-14.44 14.44h-171.2zm0 76.86c-7.97 0-14.43-6.46-14.43-14.43 0-7.96 6.46-14.43 14.43-14.43h136.42c7.97 0 14.43 6.47 14.43 14.43 0 7.97-6.46 14.43-14.43 14.43H171.97zM322.31 44.44v49.03c.96 12.3 5.21 21.9 12.65 28.26 7.8 6.66 19.58 10.41 35.23 10.69l33.39-.04-81.27-87.94zm86.83 116.78-39.17-.06c-22.79-.35-40.77-6.5-53.72-17.57-13.48-11.54-21.1-27.86-22.66-48.03l-.14-2v-64.7H141.44c-9.73 0-18.61 4-25.03 10.41C110 45.69 106 54.57 106 64.3v319.73c0 9.74 4.01 18.61 10.42 25.02 6.42 6.42 15.29 10.42 25.02 10.42H373.7c9.75 0 18.62-3.98 25.01-10.38 6.45-6.44 10.43-15.3 10.43-25.06V161.22zm-84.38 287.11H141.44c-17.68 0-33.77-7.24-45.41-18.88-11.65-11.65-18.89-27.73-18.89-45.42v-283.6H64.3c-9.74 0-18.61 4-25.03 10.41-6.41 6.42-10.41 15.29-10.41 25.03v311.35c0 9.73 4.01 18.59 10.42 25.01 6.43 6.43 15.3 10.43 25.02 10.43h225.04c9.72 0 18.59-4 25.02-10.43 6.17-6.17 10.12-14.61 10.4-23.9z"
                                                    />
                                                </svg>
                                            </span> */}
                                    </>
                                )}
                                <div
                                    className='text-center d-flex align-items-center justify-content-center'
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    {handleCardImage(el, data?.appendixList[appendixIndex])}
                                </div>
                            </AppendixCard>
                        )
                    );
                })}
                {
                    <div
                        style={{
                            background: '#EFF2F5',
                            width: '224px',
                            height: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => fileInputRef.current.click()}
                    >
                        <input
                            key={data?.appendixList?.length}
                            type='file'
                            style={{
                                display: 'none',
                            }}
                            ref={fileInputRef}
                            accept='.jpg, .png'
                            onChange={(e) => handleFileUpload(e, activeTab)}
                        />
                        <AddAppendix />
                    </div>
                }
            </div>
        </>
    );
}
