import './simpleLoading.scss';
import { ProgressBar } from 'react-bootstrap';

export const SimpleLoading = ({ isLoading, progress }) => {
    return (
        <div className={`overlay ${isLoading ? 'd-block' : 'd-none'}`}>
            <div className='loader'></div>

            {progress && <ProgressBar now={progress} label={`${progress}%`} style={{ height: '1.5rem' }} />}
        </div>
    );
};
