import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button``;

export default function LargeRoundedButton({ text, onClick, className }) {
    // console.log(onClick)
    return (
        <Wrapper className={`btn btn-lg rounded-pill ${className}`} onClick={onClick} disabled={onClick === undefined}>
            {text}
        </Wrapper>
    );
}
