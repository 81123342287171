import './quantityBody.scss';
import { ReactComponent as Icn_Add } from '../../../../../assets/images/icn_AddAppendix.svg';
import { ReactComponent as Icn_Minus } from '../../../../../assets/images/icn_Minus.svg';

const QuantityBody = ({ el, handleQuantity, className }) => {
    return (
        <div className={`bodyItem quantityBody d-flex justify-content-center align-items-center ${className}`}>
            <div className='quantityBtnArea d-flex align-items-center '>
                <button
                    className='quantityBtn'
                    onClick={() => {
                        if (el.quantity > 1) {
                            handleQuantity(el, 'minus');
                        }
                    }}
                >
                    <Icn_Minus />
                </button>
                <input type='number' className='w-100' value={el.quantity} onChange={() => {}} readOnly />
                <button
                    className='quantityBtn'
                    onClick={() => {
                        handleQuantity(el, 'plus');
                    }}
                >
                    <Icn_Add />
                </button>
            </div>
        </div>
    );
};

export default QuantityBody;
