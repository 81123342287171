import './cartDialog.scss';
import { ReactComponent as Icn_Cancel } from '../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_Trash } from '../../assets/images/icn_Trash.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CartDialog = ({ name, addCartQuantity, showCartInfo, setShowCartInfo, cartPdTypeQuantity }) => {
    const { t } = useTranslation('onlineStore'); // i18n
    const navigator = useNavigate();

    return (
        <div className={`cartInfoArea bg-white ${showCartInfo ? 'visible' : 'hidden'}`}>
            <div className='cartInfo w-100 d-flex flex-column justify-content-start'>
                <div className='titleWrap w-100 d-flex justify-content-between align-items-center'>
                    <div className='title'>{t('just_added_to_your_shopping_cart')}</div>
                    <div className='closeBtn ' onClick={() => setShowCartInfo(false)}>
                        <Icn_Cancel />
                    </div>
                </div>
                <div className='contentWrap d-flex align-items-end'>
                    <div className='image'>
                        <img src='./images/purchaseCenter/productDetail/cart1.jpg' alt='cart' />
                    </div>
                    <div className='content ps-2 '>
                        <p className='title text-start mb-1'>{name}</p>
                        <p className='num text-start mb-0'>x {addCartQuantity}</p>
                    </div>
                    {/* <div className="deleteBtn">
						<Icn_Trash />
					</div> */}
                </div>
                <div className='addCartBtnWrap d-flex justify-content-center align-items-center'>
                    <div className='btn btn-outline-primary w-100' onClick={() => navigator('/cart')}>
                        <span className='me-1'>{t('view_shopping_cart')}</span>
                        <span>{`(${cartPdTypeQuantity})`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
