import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as AddAppendix } from '../../../../assets/images/icn_AddAppendix.svg';
import ThumbnailsSection from './ThumbnailsSection';
import MainPicture from './MainPicture';

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 490px;
    background-color: #fff;

    // border: 1px solid red;
    // width: 470px;
`;

// 商品圖片區域組件 PictureSection

// 接受的 props:
// - `appendixList`: 商品附件列表的狀態
// - `setAppendixList`: 用於更新商品附件列表的函數
// - `data`: 用於商品數據的狀態
// - `setData`: 用於更新商品數據的函數
// - `isAllSpecHaveValue`: 用於檢查是否所有商品規格都有值的標誌
// - `selectedSpec`: 選中的商品規格的狀態
// - `page`: 當前所處頁面的狀態
// - `salesKitSetData`: 商品套件數據的狀態
// - `setSalesKitSetData`: 用於更新商品套件數據的函數
// - `selectedSetGuid`: 選中的商品套件的 Guid
// - `isPreview`: 是否處於預覽模式的標誌

// 使用 useRef 來創建一個文件輸入的引用。
// 使用 useState 來維護當前選中的縮略圖商品附件 Guid。
// 使用 useEffect 監聽 `appendixList` 的變化，當 `appendixList` 發生變化時，將 `activeThumbnailGuid` 設置為 null，以重置選中的縮略圖。
// 使用 useEffect 監聽 `appendixList` 和 `activeThumbnailGuid` 的變化，當 `appendixList` 或 `activeThumbnailGuid` 發生變化時，更新 `activeThumbnailGuid`。
// 渲染商品圖片區域，包括一個主圖和一個縮略圖區域。
// 主圖區域組件 `MainPicture` 接收相關的狀態和函數，並呼叫相應的函數進行操作。
// 縮略圖區域組件 `ThumbnailsSection` 接收相關的狀態和函數，並呼叫相應的函數進行操作。

// 注意：Wrapper、MainPicture、ThumbnailsSection 這些組件的具體實現未提供，需要參考這些組件的具體實現。

export default function PictureSection({
    appendixList,
    setAppendixList,
    data,
    setData,
    isAllSpecHaveValue,
    selectedSpec,
    page,
    salesKitSetData,
    setSalesKitSetData,
    selectedSetGuid,
    isPreview,
}) {
    console.log(appendixList);
    // 記住哪個 file 被按的 ref
    const appendixInputRef = useRef(null);
    // 記住哪個 Thumbnail 被按
    const [activeThumbnailGuid, setActiveThumbnailGuid] = useState(null);

    console.log(activeThumbnailGuid);

    useEffect(() => {
        setActiveThumbnailGuid(null);
    }, [appendixList]);

    useEffect(() => {
        const newAppendixList = appendixList.filter((el) => el.action !== 'delete');

        console.log(activeThumbnailGuid);

        if (!activeThumbnailGuid) {
            setActiveThumbnailGuid(newAppendixList[0]?.appendixGuid);
        }
    }, [appendixList, activeThumbnailGuid]);
    // console.log(appendixList)
    return (
        <Wrapper>
            <MainPicture
                activeThumbnailGuid={activeThumbnailGuid}
                setActiveThumbnailGuid={setActiveThumbnailGuid}
                appendixList={appendixList}
                setAppendixList={setAppendixList}
                selectedSpec={selectedSpec}
            />
            <ThumbnailsSection
                activeThumbnailGuid={activeThumbnailGuid}
                setActiveThumbnailGuid={setActiveThumbnailGuid}
                data={data}
                setData={setData}
                appendixList={appendixList}
                setAppendixList={setAppendixList}
                isAllSpecHaveValue={isAllSpecHaveValue}
                selectedSpec={selectedSpec}
                page={page}
                salesKitSetData={salesKitSetData}
                setSalesKitSetData={setSalesKitSetData}
                selectedSetGuid={selectedSetGuid}
                isPreview={isPreview}
            />
        </Wrapper>
    );
}
