import { useState, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.img`
    flex: 1; /* MainPicture takes remaining space */
    width: 100%;
    max-height: 436px; /* Set max height to Wrapper height */
    object-fit: contain; /* Maintain aspect ratio and fit within the Wrapper */
`;
// 主圖區域組件 MainPicture

// 接受的 props:
// - `appendixList`: 商品附件列表的狀態
// - `activeThumbnailGuid`: 當前選中的縮略圖商品附件 Guid
// - `setActiveThumbnailGuid`: 用於更新當前選中的縮略圖商品附件 Guid 的函數

// 在組件內部，首先使用 `filter` 方法過濾出非刪除狀態的商品附件，然後使用 `find` 方法找到與當前選中的縮略圖 Guid 相對應的商品附件。
// 通過 `activeThumbnail` 獲取當前選中的商品附件，並將其 URL 作為 `src` 屬性傳遞給包裹元素。
// 這樣，組件將根據當前選中的縮略圖來顯示相應的商品主圖。

// 注意：Wrapper 元素的具體實現未提供，需要參考具體的樣式和結構實現。

export default function MainPicture({ appendixList, activeThumbnailGuid, setActiveThumbnailGuid }) {
    console.log(activeThumbnailGuid);
    console.log(appendixList);

    const activeThumbnail = appendixList
        ?.filter((el) => el.action !== 'delete')
        .find((el) => el.appendixGuid === activeThumbnailGuid);

    console.log(activeThumbnail);

    return <Wrapper src={activeThumbnail?.appendixURL} alt={activeThumbnail?.appendixURL} />;
}
