import React, { useState, useRef } from 'react';
import { ReactComponent as Icn_Search } from '../../../assets/images/icn_Search.svg';
import { ReactComponent as Icn_Cancel } from '../../../assets/images/icn_Cancel.svg';
import styled from 'styled-components';

// #region styled-components
const SearchInputWrapper = styled.div`
    position: relative;
    height: 48px;
`;

const SearchInputButton = styled.div`
    width: 48px;
    justify-content: center;
    cursor: pointer;
`;

const Input = styled.input`
  padding-left: 48px;
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  border: none;
  outline: none;
  border-radius: 4px;

  position: relative

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #989898;
  }
  :-ms-input-placeholder {
    color: #989898;
  }

  @media only screen and (max-width: 458px) {
    background-color: white;
  }
`;

const CancelButton = styled.div`
    height: 36px;
    width: 36px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`;
// #endregion

export default function SearchInput({ handleSearch, t }) {
    const [searchText, setSearchText] = useState('');

    return (
        <SearchInputWrapper>
            <SearchInputButton
                className='position-absolute start-2 d-flex align-items-center h-100'
                onClick={() => handleSearch(searchText)}
            >
                <Icn_Search />
            </SearchInputButton>
            <Input
                type='text'
                placeholder={t('search_by_product_id_or_name')}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch(searchText)}
            />
            {searchText && (
                <CancelButton
                    onClick={() => {
                        setSearchText('');
                        handleSearch();
                    }}
                >
                    <Icn_Cancel />
                </CancelButton>
            )}
        </SearchInputWrapper>
    );
}
