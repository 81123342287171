import Swal from 'sweetalert2';

//檢查上傳檔案類型
export function checkAndSetFile(sender, validExts, isShowSuccess) {
    // 可接受的附檔名array: validExts

    let fileExt = sender.value;
    let fileName = fileExt.substring(fileExt.lastIndexOf('\\')).split('\\')[1];

    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (fileExt === '') {
        // 假使第一次按上傳檔案後，第二次按取消時
    } else if (validExts.indexOf(fileExt) < 0) {
        Swal.fire({
            icon: 'error',
            title: '檔案類型錯誤',
            text: `可上傳副檔名如下： ${validExts.toString()}`,
        });
        sender.value = null;
        return false;
    } else if (!isShowSuccess) {
        return true;
    } else {
        Swal.fire({
            icon: 'success',
            title: `${fileName} 上傳成功`,
            timer: 800,
            width: 600,
            padding: '3em',
            color: '#716add',
            background: '#fff url(/images/trees.png)',
            backdrop: `
		    rgba(0,0,123,0.4)
		    url("/images/nyan-cat2.gif")
		    left top
		    no-repeat
		  `,
        });
        return true;
    }
}
