import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import PictureSection from '../components/PictureSection';
import Counter from '../components/Counter';
import LargeRoundedButton from '../components/LargeRoundedButton';
import SalesKitDescriptionModal from '../components/Modals/SalesKitDescriptionModal';
import SalesKitContentModal from '../components/Modals/SalesKitContentModal';
import { Link, useNavigate } from 'react-router-dom';

// #region styled-components
const PaymentSection = styled.section`
    background-color: #fff;
    // height: 570px;
    width: 368px;
    padding: 16px;

    // border: 1px solid blue
`;

const UnitPrice = styled.div`
    font-size: 12px;
    color: gray;
`;

const Spot = styled.div`
    background: pink;
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
`;

const Line = styled.div`
    font-size: 14px;
`;

const FeatureWrapper = styled.div`
    margin-bottom: 16px;
`;

const PaymentSectionOptionTitle = styled.div`
    color: gray;
    margin-bottom: 8px;
    font-size: 14px;
`;

const FeatureOptionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const FeatureOption = styled.button`
    background-color: white;
    color: #989898;
    padding: 8px 16px;
    margin-right: 8px;
`;

const TotalPriceWrapper = styled.div`
    color: #989898;
    margin-top: 36px;
    margin-bottom: 36px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
`;

const SelectFromText = styled.div`
    cursor: pointer;
    font-size: 12px;
    width: 72px;
`;

const ContentSection = styled.div`
    width: 100%;
    min-height: 250px;
    border: 1px solid #dee2e6;
`;
//#endregion

// `Page2` 組件用於編輯銷售活動的第二步，包含商品預設照片和商品描述的編輯。

// 主要功能：
// - 提供商品預設照片的編輯區域，包括圖片上傳、商品名稱、價格顯示、商品描述等。
// - 提供商品描述的編輯區域，可以修改商品描述內容。
// - 提供按鈕觸發修改商品描述和修改商品內容的模態窗口。
// - 提供保存和跳轉到下一步的按鈕，具體操作由父組件控制。

// 注意：
// - 商品的編輯和保存邏輯未完全提供，可能需要進一步根據項目需求進行實現。
// - 使用了 `Swal` 库實現確認窗口的彈出。
// - `PictureSection`、`PaymentSection`、`FeatureWrapper` 等組件未提供，需確保其正確引入。
// - 表單控件的值和變更事件由 `handleSalesKitDataChange` 函數管理。
// - 商品描述和商品內容的編輯和保存，分別使用 `SalesKitDescriptionModal` 和 `SalesKitContentModal` 模態窗口進行。
// - 利用 `dayjs` 库來格式化日期，確保該庫的正確引入。

// 使用方式：
// - 作為 `SalSalesKitUpdate` 組件的子組件之一，通過父組件的 `setData`、`setPage`、`navigate` 等函數進行數據管理和頁面控制。
// - 通過 `data` 狀態獲取商品主檔的當前信息，通過 `handleSalesKitDataChange` 函數實現信息編輯。
// - 通過 `handleSubmit` 函數實現商品信息的保存。
// - 通過 `handleSalesKitDescModelOpen` 和 `handleSalesKitContentModelOpen` 函數觸發相應的模態窗口。
// - 利用 `PictureSection`、`PaymentSection`、`FeatureWrapper` 等組件進行畫面的構建。

export default function Page2({ salesKitName, salesKitGuid, setPage, data, setData, page, page2Data }) {
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const totalPrice = `未有價格(未選規格)`;
    const [linesList, setLinesList] = useState([]);
    const [salesKitAppendixList, setSalesKitAppendixList] = useState([]);

    const [salesKitDescriptionModalOpen, setSalesKitDescriptionModalOpen] = useState(false);
    const [salesKitContentModalOpen, setSalesKitContentModalOpen] = useState(false);

    const handleSalesKitDescModelOpen = () => setSalesKitDescriptionModalOpen(true);
    const handleSalesKitContentModelOpen = () => setSalesKitContentModalOpen(true);

    const handleMinus = () => {
        setCount((prev) => prev - 1);
    };

    const handlePlus = () => {
        setCount((prev) => prev + 1);
    };

    useEffect(() => {
        const linesList = (data?.salesKit?.note || '').split('<br />');

        setLinesList(linesList);
    }, [data?.salesKit?.note]);

    const handleNextPage = () => {
        setData({
            ...data,
            salesKit: {
                ...data.salesKit,
                linesList,
            },
        });

        setPage(3);
    };

    const handleSubmit = () => {
        // console.log(data);
        // console.log(salesKitAppendixList)

        axios.put(`web/SalSalesKit/updateSalesKit`, {
            ...data,
            salesKitAppendixList,
        });
    };

    useEffect(() => {
        setSalesKitAppendixList(data?.pingtexSalesKitAppendixList);
    }, []);

    // console.log(data?.salesKit)

    return (
        <section id='section-main'>
            <div id='container-activityUpdate'>
                <div className='d-flex'>
                    <h5 className='fw-bold mb-4'>商品預設照片 - {salesKitName}</h5>
                    <button
                        className='btnCancel btn btn-outline-primary px-4 me-2 ms-auto'
                        style={{
                            height: '32px',
                            paddingBlock: 0,
                            fontSize: '14px',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}
                    >
                        取消
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary text-white px-4 me-2'
                        style={{
                            height: '32px',
                            paddingBlock: 0,
                            fontSize: '14px',
                        }}
                        onClick={handleSubmit}
                    >
                        儲存
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary text-white px-4'
                        style={{
                            height: '32px',
                            paddingBlock: 0,
                            fontSize: '14px',
                        }}
                        onClick={handleNextPage}
                    >
                        下一步
                    </button>
                </div>

                <div className='infoWrapper p-4 rounded-4 mb-3'>
                    <div className='infoTop d-flex align-items-center mb-3 '>
                        <div className='d-flex w-100'>
                            <PictureSection
                                // appendixList={data?.salesKitAppendixStoreList}
                                data={data}
                                setData={setData}
                                appendixList={salesKitAppendixList}
                                setAppendixList={setSalesKitAppendixList}
                                page={page}
                            />
                            <PaymentSection>
                                <h3>{data?.salesKit?.name_01}</h3>
                                <UnitPrice>未有價格(未選規格)</UnitPrice>
                                <hr />
                                {linesList.map((line, index) => (
                                    <div className='d-flex align-items-center mb-2'>
                                        <Spot />
                                        <Line key={index}>{line}</Line>
                                    </div>
                                ))}
                                <SelectFromText
                                    className='text-primary ms-auto me-2 text-decoration-underline'
                                    onClick={handleSalesKitDescModelOpen}
                                >
                                    修改商品描述
                                </SelectFromText>
                                <hr />
                                {data?.featureList.map((feature, idx) => (
                                    <FeatureWrapper key={idx}>
                                        <PaymentSectionOptionTitle>{feature.name01}</PaymentSectionOptionTitle>
                                        <FeatureOptionsWrapper>
                                            {data[`spec${idx + 1}List`]?.map((spec, idx) => (
                                                <FeatureOption
                                                    className='btn btn-sm btn-outline-primary disabled'
                                                    key={`${spec.name01}${idx}`}
                                                >
                                                    {spec.name01}
                                                </FeatureOption>
                                            ))}
                                        </FeatureOptionsWrapper>
                                    </FeatureWrapper>
                                ))}
                                <hr />
                                <PaymentSectionOptionTitle>數量</PaymentSectionOptionTitle>
                                <Counter
                                    count={count}
                                    handleMinus={handleMinus}
                                    handlePlus={handlePlus}
                                    disabled={true}
                                />
                                <TotalPriceWrapper>
                                    <div>訂單總額</div>
                                    <div className='text-primary'>{totalPrice}</div>
                                </TotalPriceWrapper>
                                <div className='d-flex align-items-center justify-content-around'>
                                    <LargeRoundedButton text='加入購物車' className='btn-outline-primary text-dark' />
                                    <LargeRoundedButton text='直接購買' className='btn-primary text-white' />
                                </div>
                            </PaymentSection>
                        </div>
                    </div>
                    <SelectFromText
                        className='text-primary me-auto mb-2 text-decoration-underline'
                        onClick={handleSalesKitContentModelOpen}
                    >
                        修改商品內容
                    </SelectFromText>
                    <ContentSection>
                        <section
                            className='not-found-controller'
                            dangerouslySetInnerHTML={{
                                __html: data?.salesKit?.content,
                            }}
                        />
                    </ContentSection>
                </div>
            </div>
            {salesKitDescriptionModalOpen && (
                <SalesKitDescriptionModal
                    note={data?.salesKit?.note}
                    data={data}
                    setData={setData}
                    modalOpen={salesKitDescriptionModalOpen}
                    setModalOpen={setSalesKitDescriptionModalOpen}
                />
            )}
            {salesKitContentModalOpen && (
                <SalesKitContentModal
                    content={data?.salesKit?.content}
                    data={data}
                    setData={setData}
                    modalOpen={salesKitContentModalOpen}
                    setModalOpen={setSalesKitContentModalOpen}
                />
            )}
        </section>
    );
}
