import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as Icn_Maintenance } from '../../assets/images/maintenance.svg';

export default function MaintenancePage() {
    const { t } = useTranslation('maintenance'); // i18n

    return (
        <>
            <div className='notFoundPageWrapper bg-white'>
                <div className='d-flex flex-column align-items-center'>
                    <div className='section1 w-100 position-relative mt-11 mt-lg-10 mb-lg-10'>
                        <div className='imgWrapper d-flex justify-content-center align-items-center rounded-circle bg-background mx-auto'>
                            {/* <img
								src='./images/notFoundPage/section1_1_2x.png'
								alt='notFoundPage'
							/> */}
                            <Icn_Maintenance />
                        </div>

                        <div className='section2 position-absolute text-center w-100'>
                            <h1 className='text-grey5 fw-bold mb-3'>{t('sorry_page_not_available')}</h1>
                            <h6 className='text-grey4 mb-1'>{t('you_might_have_entered_wrong_url')}</h6>
                            <h6 className='text-grey4'>{t('click_button_below_to_return_to_home')}</h6>
                        </div>
                    </div>
                    <div className='section3 mt-12 mt-lg-14 mb-15 mb-lg-14'>
                        <Link to='/'>
                            <div className='btn btn-primary text-white rounded-pill'>{t('return_to_home')}</div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
