export const generateEditLog = (pageName, t, updated, original) => {
    // const t = i18n.getFixedT('en');
    console.log(updated, original, t);

    let updateContent = '';

    Object.keys(original).forEach((key) => {
        // console.log(key);
        const originalVal = original[key];
        const updatedVal = updated[key];
        // console.log('originalVal', originalVal);
        // console.log('updatedVal', updatedVal);

        if (originalVal === updatedVal) {
            return;
        }

        const keyName = t(`${pageName}:${key}`);
        // console.log(keyName);

        const content = t('log.edit', {
            key: `[${keyName}]`,
            originalVal: `'${originalVal}'`,
            updatedVal: `'${updatedVal}'`,
        });

        return (updateContent += `${content} `);
    });

    console.log(updateContent);
    return updateContent;
};
