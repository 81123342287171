import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnRouteChange = ({ height }) => {
    const location = useLocation();

    const isPC = () => {
        const mediaQuery = window.matchMedia('(min-width: 768px)');
        return mediaQuery.matches;
    };

    useEffect(() => {
        if (isPC()) {
            window.scrollTo(0, height); // 捲動到800像素的位置
        }
    }, [location]);

    return null;
};

export default ScrollToTopOnRouteChange;
