import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// 使用 i18next-http-backend
i18n.use(Backend)
    // 將 i18next 傳入 react-i18next 裡面
    .use(initReactI18next)
    // 實例化 initReactI18next
    .init({
        // debug: true,
        backend: {
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json', // 網頁載入時去下載語言檔的位置
        },
        fallbackLng: 'zh', // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
        lng: 'zh', // 預設語言
        // 會使用到的 namespace
        ns: ['common'], // 空陣列，不預先載入任何命名空間
        interpolation: {
            escapeValue: false, // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
            formatSeparator: ',',
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
