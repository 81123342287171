import './cms.scss';
import React from 'react';

const Cms = () => {
    return (
        <section id='section-main'>
            <div id='container-cms'></div>
        </section>
    );
};

export default Cms;
