import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useInitialLocation = () => {
    const location = useLocation();
    const [elDistanceToTop, setElDistanceToTop] = useState(0);

    useEffect(() => {
        const { initialLocation } = location.state ? location.state : '';
        // console.log('location', location);

        // if (!initialLocation) return window.scrollTo(0, 0);
        const el = document.querySelector(initialLocation);

        if (el) {
            // console.log(el);
            // console.log(window.pageYOffset);
            // console.log(el.getBoundingClientRect().top);

            setElDistanceToTop(window.pageYOffset + el.getBoundingClientRect().top - 78);
        } else {
            setElDistanceToTop(0);
        }

        // window.scrollTo(0, elDistanceToTop);
    }, [location.state]);

    // console.log(elDistanceToTop);
    return elDistanceToTop;
};

export default useInitialLocation;
