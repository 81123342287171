import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { NormalButton } from './Button';

// #region styled-components

const CreateSearchBtn = styled.button`
    width: 35px;
    height: 35px;
    border-radius: 12px;

    &:hover > .add_svg {
        path {
            fill: white;
        }
    }
`;

const ModalThead = styled.thead`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
`;
const ModalTh = styled.th`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
    border: none !important;
`;
const ModalLabel = styled.label`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
`;
const ModalInput = styled.input`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
`;

const SearchModalInput = styled.input`
    padding: 0 0.75rem;
    cursor: pointer !important;
    text-transform: uppercase;
`;

const ModalSearchBtn = styled.button`
    top: 1px;
    right: 1px;
    height: 33px;
    border-radius: 0 0.25rem 0.25rem 0;
`;

const SearchSelect = styled.select`
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(206, 212, 218);
    border-right-color: rgb(206, 212, 218);
    border-bottom-color: rgb(206, 212, 218);
    border-left-color: rgb(206, 212, 218);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    appearance: none;
    border-radius: 0.25rem;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
`;
//#endregion

function AdvancedSearchModal(props) {
    const {
        isOpen,
        handleClose,
        advancedSearchDataTemp,
        setAdvancedSearchDataList,
        displayList,
        searchContentList,
        fetchData,
        ns,
        restSearchData,
        fixedListData,
    } = props;

    const { t, i18n } = useTranslation(ns);

    const advancedSearchListAPI = `api/Logistics/${ns}/advancedSearchDataList`;
    const [shouldFocus, setShouldFocus] = useState(-1); // 初始值為 -1，表示不應該聚焦任何 input 元素
    const [dataList, setDataList] = useState([]);
    const [modalDataList, setModalDataList] = useState([{ ...advancedSearchDataTemp }]);

    /* #region input box relayed */
    //記錄 composition 狀態
    let isOnComposition = false;
    const handleComposition = (e, index) => {
        console.log(e.type);

        if (e.type === 'compositionend') {
            // composition結束，代表中文輸入完成
            console.log('compositionend', e.target.value);
            isOnComposition = false;
            const newAdvancedSearchDataList = [...modalDataList];
            newAdvancedSearchDataList[index].searchValue = e.target.value;
            newAdvancedSearchDataList[index].displaySearchValue = e.target.value;
            setModalDataList(newAdvancedSearchDataList);
        } else {
            // composition進行中，代表正在輸入中文
            isOnComposition = true;
        }
    };

    // 改變 focus 的 input 位置
    const handleInputFocus = (index) => {
        setShouldFocus(index);
    };

    /* #endregion */

    /* #region dynamically create second input and third input */
    // 由第一行 select option, 動態產生第二行的 option
    const SearchOperatorOption = ({ column, selectOptionList, index }) => {
        return (
            <>
                {selectOptionList.map((opt) => {
                    if (opt === '==') {
                        return (
                            <option key={`${column}${index}==`} value='==' className='k12 k17 ks'>
                                等於
                                {/* {t('common:advancedSearch.==')} */}
                            </option>
                        );
                    } else if (opt === '!=') {
                        return (
                            <option key={`${column}${index}!=`} value='!=' className='k12 k17 ks'>
                                不等於
                                {/* {t('common:advancedSearch.!=')} */}
                            </option>
                        );
                    } else if (opt === 'contains') {
                        return (
                            <option key={`${column}${index}contains`} value='contains' className='k12 k17 ks'>
                                包括
                                {/* {t('common:advancedSearch.contains')} */}
                            </option>
                        );
                    } else if (opt === '!contains') {
                        return (
                            <option key={`${column}${index}!contains`} value='!contains' className='k12 k17 ks'>
                                不包括
                                {/* {t('common:advancedSearch.!contains')} */}
                            </option>
                        );
                    } else if (opt === '>') {
                        return (
                            <option key={`${column}${index}>`} value='>' className='k12 k17 ks'>
                                大於
                                {/* {t('common:advancedSearch.>')} */}
                            </option>
                        );
                    } else if (opt === '>=') {
                        return (
                            <option key={`${column}${index}>=`} value='>=' className='k12 k17 ks'>
                                大於等於
                                {/* {t('common:advancedSearch.>=')} */}
                            </option>
                        );
                    } else if (opt === '<') {
                        return (
                            <option key={`${column}${index}<`} value='<' className='k12 k17 ks'>
                                小於
                                {/* {t('common:advancedSearch.<')} */}
                            </option>
                        );
                    } else if (opt === '<=') {
                        return (
                            <option key={`${column}${index}<=`} value='<=' className='k12 k17 ks'>
                                小於等於
                                {/* {t('common:advancedSearch.<=')} */}
                            </option>
                        );
                    }
                })}
            </>
        );
    };

    // 由第一行 select option, 動態產生第三行的 input
    const SearchInput = ({ column, index }) => {
        // console.log(column, index);
        let type;
        let contentPrefix;
        let optionsDisplayList;
        let guid;
        let listName;

        searchContentList.map((el) => {
            if (el.column === column) {
                type = el.type;
                contentPrefix = el.name;
                optionsDisplayList = el?.options;
                guid = el?.searchFromDataBase;
                listName = el.listName;
            }
        });

        const inputProps = {
            type: type !== 'list' ? type : undefined,
            name: 'multiSearch',
            maxLength: '100',
            className: 'form-control fontSize875Rem',
        };

        if (type === 'list') {
            const options = dataList[`${listName}`]; // eg. currencyList
            const optionElements = options?.map((option) => {
                let content = '';

                // console.log(column);
                // console.log(optionsValue);

                const optionsValue = searchContentList.find((item) => item.column === column)?.optionsValue;

                optionsDisplayList.map((optionDisplay) => (content += `${option[optionDisplay]} `));

                return (
                    <option value={option[optionsValue]} key={option[optionsValue]}>
                        {content}
                    </option>
                );
            });

            return (
                <>
                    <select
                        key={`select${column}${index}`}
                        id={`select${column}${index}`}
                        {...inputProps}
                        defaultValue={modalDataList[index].searchValue}
                        onChange={(e) => {
                            // console.log(e.target.value);
                            // 複製 newAdvancedSearchDataList 陣列
                            const newAdvancedSearchDataList = [...modalDataList];

                            // update searchValue
                            // 更新第 index 筆資的 searchVale 為 newValue
                            newAdvancedSearchDataList[index].searchValue = e.target.value;

                            // update searchContent
                            const i = e.nativeEvent.target.selectedIndex;
                            const content = e.nativeEvent.target[i].text;

                            // 更新第 index 筆資的 filterContent 為 newValue
                            // newAdvancedSearchDataList[
                            //     index
                            // ].searchContent = `${contentPrefix}="${content}"`;
                            newAdvancedSearchDataList[index].displaySearchValue = content;

                            // 使用更新後的陣列來更新 searchValue 的狀態
                            setModalDataList(newAdvancedSearchDataList);
                        }}
                    >
                        <option key={`option${index}0`} value=''>
                            {t('common:advancedSearch.pleaseSelect')}
                        </option>
                        {optionElements}
                    </select>
                </>
            );
        }

        return (
            <>
                <input
                    {...inputProps}
                    autoFocus={shouldFocus === index}
                    shouldfocus={shouldFocus}
                    onFocus={() => handleInputFocus(index)}
                    defaultValue={modalDataList[index].searchValue}
                    onCompositionStart={(e) => handleComposition(e, index)}
                    onCompositionUpdate={(e) => handleComposition(e, index)}
                    onCompositionEnd={(e) => handleComposition(e, index)}
                    onChange={(e) => {
                        if (e.target instanceof HTMLInputElement && !isOnComposition) {
                            const newAdvancedSearchDataList = [...modalDataList];

                            newAdvancedSearchDataList[index].searchValue = e.target.value.toUpperCase();

                            // newAdvancedSearchDataList[
                            //     index
                            // ].searchContent = `${contentPrefix}="${e.target.value}"`;

                            newAdvancedSearchDataList[index].displaySearchValue = e.target.value;

                            console.log(newAdvancedSearchDataList);

                            setModalDataList(newAdvancedSearchDataList);
                            // setAdvancedSearchDataList(newAdvancedSearchDataList);
                        }
                    }}
                />
            </>
        );
    };
    /* #endregion */

    /* #region search bar related */
    const newSearchBar = () => {
        // console.log(advancedSearchDataTemp);
        setModalDataList([...modalDataList, { ...advancedSearchDataTemp }]);
    };

    const deleteSearchBar = (index) => {
        setModalDataList(modalDataList.filter((_, i) => i !== index));
    };

    const deleteAllSearchBar = () => {
        setModalDataList([{ ...advancedSearchDataTemp }]);
    };
    /* #endregion */

    const handleSearchLogicChange = (value, index) => {
        const newModalDataList = [...modalDataList];

        newModalDataList[index].searchLogic = value;
        setModalDataList(newModalDataList);
    };

    const handleSearchFilterChange = (value, index, data) => {
        const searchContent = searchContentList.find((item) => item.column === value);

        // console.log(searchContent)

        modalDataList[index].column = value;
        modalDataList[index].selectOptionList = searchContent.selectOptionList;
        modalDataList[index].searchValue = '';
        modalDataList[index].searchOperator = searchContent.selectOptionList[0];

        setModalDataList([...modalDataList]);
    };

    const handleSearchOperatorChange = (value, index, column) => {
        modalDataList[index][column] = value;
        setModalDataList([...modalDataList]);
    };

    const advancedSearchSubmit = () => {
        let isValid = true;
        const newModalDataList = [...modalDataList];
        // console.log(newModalDataList);

        // 查看 search row 是否合格, 並更改其 warning msg
        newModalDataList.map((el, index) => {
            if (!el.searchValue) {
                newModalDataList[index].inputWarningContent = t(
                    // 'common:advancedSearch.enterSearchValue'
                    '請輸入搜尋值'
                );
                setModalDataList(newModalDataList);
                return;
            } else {
                newModalDataList[index].inputWarningContent = '';
                let tagSymbol = '';

                if (newModalDataList[index].searchOperator === '==') {
                    tagSymbol = '=';
                } else if (newModalDataList[index].searchOperator === '!=') {
                    tagSymbol = '≠';
                } else if (newModalDataList[index].searchOperator === 'contains') {
                    tagSymbol = '⊇';
                } else if (newModalDataList[index].searchOperator === '!contains') {
                    tagSymbol = '⊉';
                } else if (newModalDataList[index].searchOperator === '>') {
                    tagSymbol = '>';
                } else if (newModalDataList[index].searchOperator === '>=') {
                    tagSymbol = '>=';
                } else if (newModalDataList[index].searchOperator === '<') {
                    tagSymbol = '<';
                } else if (newModalDataList[index].searchOperator === '<=') {
                    tagSymbol = '<=';
                }

                newModalDataList[index].tagSymbol = tagSymbol;
                setModalDataList(newModalDataList);
            }
        });

        // 查看是否全部 search row 都合格
        modalDataList.some((el, index) => modalDataList[index].inputWarningContent && (isValid = false));

        if (isValid) {
            // 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
            // 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
            newModalDataList.forEach((el) => {
                for (const item of searchContentList) {
                    console.log(el);
                    console.log(item);

                    if (el.column === item.column) {
                        el.searchFilter = item.tableColumn;

                        break; // 找到匹配後中斷迴圈
                    }
                }
            });

            const updatedModalDataList = [];

            for (const item of newModalDataList) {
                console.log(item.searchFilter);

                if (item.searchFilter === 'name') {
                    // 保留 item 的其他屬性，只更改 searchFilter
                    updatedModalDataList.push({
                        ...item,
                        searchFilter: 'name01',
                    });
                    updatedModalDataList.push({
                        ...item,
                        searchFilter: 'name02',
                        searchLogic: 'OR',
                    });
                    updatedModalDataList.push({
                        ...item,
                        searchFilter: 'name03',
                        searchLogic: 'OR',
                    });
                } else {
                    updatedModalDataList.push(item);
                }
            }

            // call api
            // add tag info
            newModalDataList[0]['searchLogic'] = '';
            updatedModalDataList[0]['searchLogic'] = '';
            // 使用更新後的陣列
            setModalDataList(newModalDataList);
            setAdvancedSearchDataList(newModalDataList);

            console.log(newModalDataList);

            handleClose();
            fetchData(updatedModalDataList);
        }
    };

    useEffect(() => {
        console.log(fixedListData);
        !fixedListData
            ? advancedSearchListAPI &&
              fetch(advancedSearchListAPI)
                  .then((response) => response.json())
                  .then((data) => {
                      const restSearchList = restSearchData ? restSearchData : [];
                      const allData = { ...data, ...restSearchList };
                      // console.log('advancedSearchDataList', data);
                      setDataList(allData);
                  })
            : setDataList({ ...restSearchData, ...fixedListData });
    }, []);

    // console.log(dataList);

    return (
        <div key='advancedSearchModal'>
            <Modal isOpen={isOpen} centered={true} style={{ maxWidth: '624px' }}>
                <ModalHeader className='position-relative pt-4 pb-2 ps-4'>
                    <p style={{ fontSize: '20px', color: 'var(--primary)' }} className='fw-bold mb-1'>
                        {t('common:進階查詢')}
                    </p>
                    <button
                        style={{ top: '12px', right: '12px' }}
                        type='button'
                        className='close position-absolute btn'
                        onClick={handleClose}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            id='icn_Clear'
                            viewBox='0 0 16 16'
                            width='16'
                            height='16'
                        >
                            <defs>
                                <clipPath id='clipPath-clear22'>
                                    <rect
                                        id='Rectangle_3088'
                                        fill='#686868'
                                        width='10'
                                        height='10'
                                        data-name='Rectangle 3088'
                                    />
                                </clipPath>
                            </defs>
                            <rect id='Rectangle_3086' fill='none' width='16' height='16' data-name='Rectangle 3086' />
                            <g id='Group_6190' transform='translate(3 3)' data-name='Group 6190'>
                                <g id='Group_6188' clipPath='url("#clipPath")' data-name='Group 6188'>
                                    <path
                                        id='Path_1410'
                                        fill='#686868'
                                        transform='translate(0)'
                                        d='M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z'
                                        data-name='Path 1410'
                                    />
                                </g>
                            </g>
                        </svg>
                    </button>
                </ModalHeader>
                <ModalBody className='px-6 pt-6 pb-0'>
                    <h6 className='ms-3 mb-3 fw-bold fontSize875Rem'>
                        <span className='me-2'>
                            <svg
                                className='mb-1'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 12 12'
                                width='12'
                                height='12'
                            >
                                <defs>
                                    <clipPath id='clipPath-selectBlack'>
                                        <rect
                                            id='Rectangle_2972'
                                            fill='var(--secondary)'
                                            width='12'
                                            height='12'
                                            data-name='Rectangle 2972'
                                        ></rect>
                                    </clipPath>
                                </defs>
                                <g id='Group_6202' transform='translate(0 -0.143)' data-name='Group 6202'>
                                    <g
                                        id='Group_5146'
                                        clipPath='url("#clipPath")'
                                        transform='translate(0 0.143)'
                                        data-name='Group 5146'
                                    >
                                        <path
                                            id='Path_1318'
                                            fill='var(--secondary)'
                                            transform='translate(0 -0.107)'
                                            d='M 12.056 0.286 A 0.5 0.5 0 0 0 11.6 0 H 0.5 A 0.5 0.5 0 0 0 0.049 0.289 A 0.511 0.511 0 0 0 0.112 0.827 L 4.435 6.233 V 11.6 a 0.5 0.5 0 0 0 0.871 0.349 l 2.381 -2.54 a 0.511 0.511 0 0 0 0.138 -0.349 V 6.185 L 12 0.822 a 0.511 0.511 0 0 0 0.056 -0.536'
                                            data-name='Path 1318'
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <span className='text-secondary me-1'>{t('common:進階查詢')}</span>
                        <span onClick={deleteAllSearchBar} style={{ cursor: 'pointer' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                id='icn_Clearsmall'
                                viewBox='0 0 16 16'
                                width='16'
                                height='16'
                            >
                                <defs>
                                    <clipPath id='clipPath-clearSmall'>
                                        <rect
                                            id='Rectangle_3089'
                                            fill='var(--warning)'
                                            width='12'
                                            height='12'
                                            data-name='Rectangle 3089'
                                        ></rect>
                                    </clipPath>
                                </defs>
                                <rect
                                    id='Rectangle_3326'
                                    fill='none'
                                    width='16'
                                    height='16'
                                    data-name='Rectangle 3326'
                                ></rect>
                                <g
                                    id='Group_6191'
                                    clipPath='url("#clipPath")'
                                    transform='translate(2 2)'
                                    data-name='Group 6191'
                                >
                                    <path
                                        id='Path_1411'
                                        fill='var(--warning)'
                                        transform='translate(-0.577 -0.577)'
                                        d='M 8.943 5.116 a 0.546 0.546 0 0 0 -0.744 -0.8 L 6.6 5.806 l -1.488 -1.6 a 0.546 0.546 0 0 0 -0.8 0.744 l 1.487 1.6 l -1.6 1.488 a 0.546 0.546 0 1 0 0.744 0.8 l 1.6 -1.488 l 1.487 1.6 a 0.546 0.546 0 1 0 0.8 -0.744 L 7.347 6.6 Z'
                                        data-name='Path 1411'
                                    ></path>
                                    <path
                                        id='Path_1412'
                                        fill='var(--warning)'
                                        fillRule='evenodd'
                                        d='M 0 6 a 6 6 0 1 1 6 6 A 6 6 0 0 1 0 6 m 6 4.909 A 4.909 4.909 0 1 1 10.909 6 A 4.909 4.909 0 0 1 6 10.909'
                                        data-name='Path 1412'
                                    ></path>
                                </g>
                            </svg>
                        </span>
                    </h6>
                    <div className='wrap mx-4'>
                        <div className='row mb-2'>
                            <div id='searchBox' className='form-actions no-color col'>
                                {modalDataList?.map((data, index) => {
                                    // console.log(data);
                                    return (
                                        <div key={`searchBar${index}`}>
                                            <div className='searchBar row justify-content-start mb-1 mt-3 firstSelect w-auto'>
                                                {index > 0 ? (
                                                    <SearchSelect
                                                        className='col-1 me-2 px-1 fontSize875Rem'
                                                        onChange={(e) => handleSearchLogicChange(e.target.value, index)}
                                                        value={data.searchLogic}
                                                    >
                                                        <option value='AND'>
                                                            {/* {t(
                                                                'common:advancedSearch.and'
                                                            )} */}
                                                            且
                                                        </option>
                                                        <option value='OR'>
                                                            {/* {t(
                                                                'common:advancedSearch.or'
                                                            )} */}
                                                            或
                                                        </option>
                                                    </SearchSelect>
                                                ) : (
                                                    <div className='col-1 me-2 px-0 fontSize875Rem'></div>
                                                )}
                                                <SearchSelect
                                                    className='selectOption multiSearchObj fontSize875Rem col-3'
                                                    name='multiSearchObj'
                                                    defaultValue={data.column}
                                                    onChange={(e) =>
                                                        handleSearchFilterChange(e.target.value, index, data)
                                                    }
                                                >
                                                    {searchContentList.map((el, index) => {
                                                        return (
                                                            <option
                                                                key={el.column}
                                                                value={el.column}
                                                                searchtype={el.type}
                                                                selectoption={el.selectOptionList}
                                                            >
                                                                {/* {t(
                                                                        `${ns}:advancedSearch.${el.column}`
                                                                    )} */}
                                                                {el.name}
                                                            </option>
                                                        );
                                                    })}
                                                </SearchSelect>
                                                <SearchSelect
                                                    className='ms-2 selectOption multiSearchObj fontSize875Rem col-2 '
                                                    id='multiCompareObj'
                                                    name='multiCompareObj'
                                                    onChange={(e) =>
                                                        handleSearchOperatorChange(
                                                            e.target.value,
                                                            index,
                                                            'searchOperator'
                                                        )
                                                    }
                                                    value={data.searchOperator}
                                                >
                                                    {/* <option value="">請選擇</option> */}
                                                    <SearchOperatorOption
                                                        index={index}
                                                        column={data.column}
                                                        selectOptionList={data.selectOptionList}
                                                    />
                                                </SearchSelect>
                                                <span className='inputSpan col-4 mx-2 px-0'>
                                                    <SearchInput key={data.column} column={data.column} index={index} />
                                                </span>
                                                {modalDataList.length > 1 && (
                                                    <button
                                                        type='button'
                                                        className='col d-flex align-items-center p-0 bg-white border-0'
                                                        style={{
                                                            height: '35px',
                                                        }}
                                                        onClick={(e) => {
                                                            deleteSearchBar(index);
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            id='icn_Clearsmall'
                                                            viewBox='0 0 16 16'
                                                            width='16'
                                                            height='16'
                                                        >
                                                            <defs>
                                                                <clipPath id='clipPath-clearSmallGrey'>
                                                                    <rect
                                                                        id='Rectangle_3089'
                                                                        fill='#989898'
                                                                        width='12'
                                                                        height='12'
                                                                        data-name='Rectangle 3089'
                                                                    ></rect>
                                                                </clipPath>
                                                            </defs>
                                                            <rect
                                                                id='Rectangle_3326'
                                                                fill='none'
                                                                width='16'
                                                                height='16'
                                                                data-name='Rectangle 3326'
                                                            ></rect>
                                                            <g
                                                                id='Group_6191'
                                                                clipPath='url("#clip-path-clearSmallGrey")'
                                                                transform='translate(2 2)'
                                                                data-name='Group 6191'
                                                            >
                                                                <path
                                                                    id='Path_1411'
                                                                    fill='#989898'
                                                                    transform='translate(-0.577 -0.577)'
                                                                    d='M 8.943 5.116 a 0.546 0.546 0 0 0 -0.744 -0.8 L 6.6 5.806 l -1.488 -1.6 a 0.546 0.546 0 0 0 -0.8 0.744 l 1.487 1.6 l -1.6 1.488 a 0.546 0.546 0 1 0 0.744 0.8 l 1.6 -1.488 l 1.487 1.6 a 0.546 0.546 0 1 0 0.8 -0.744 L 7.347 6.6 Z'
                                                                    data-name='Path 1411'
                                                                ></path>
                                                                <path
                                                                    id='Path_1412'
                                                                    fill='#989898'
                                                                    fillRule='evenodd'
                                                                    d='M 0 6 a 6 6 0 1 1 6 6 A 6 6 0 0 1 0 6 m 6 4.909 A 4.909 4.909 0 1 1 10.909 6 A 4.909 4.909 0 0 1 6 10.909'
                                                                    data-name='Path 1412'
                                                                ></path>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                )}
                                            </div>
                                            <div className='row'>
                                                <div className='col-6 mx-1'></div>
                                                <div
                                                    className={`col text-danger ${
                                                        !modalDataList[index].inputWarningContent && 'd-none'
                                                    }`}
                                                >
                                                    {modalDataList[index].inputWarningContent}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='row border-primarylt border-bottom'>
                                <div className='col-1 me-2 px-0 fontSize875Rem'></div>
                                <div className='col p-0'>
                                    <CreateSearchBtn
                                        className='createSearchBtn d-flex align-items-center justify-content-center btn btn-outline-primary p-0 mb-5 mt-3'
                                        onClick={newSearchBar}
                                    >
                                        <svg
                                            className='add_svg'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 12 12'
                                            width='12'
                                            height='12'
                                        >
                                            <path
                                                id='icn_CreateSearch'
                                                fill='var(--primary)'
                                                d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                data-name=' ↳Color'
                                            ></path>
                                        </svg>
                                    </CreateSearchBtn>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='modal-footer justify-content-center border-0 pt-3 pb-4'>
                    <NormalButton
                        className='btn-color-main btn-size-m me-2'
                        keyName='advancedSearchSubmit'
                        word={t('common:button.confirmButton')}
                        handleClick={advancedSearchSubmit}
                    />
                    <NormalButton
                        className='btn-color-main-outline btn-size-m mx-1'
                        keyName='advancedSearchCancel'
                        word={t('common:button.cancelButton')}
                        handleClick={() => {
                            handleClose();
                        }}
                    />
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AdvancedSearchModal;
