import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as AddAppendix } from '../../../../assets/images/icn_AddAppendix.svg';
import { ReactComponent as Icn_chevronLeft } from '../../../../assets/images/icn_chevron-left.svg';
import { ReactComponent as Icn_chevronRight } from '../../../../assets/images/icn_chevron-right.svg';
import SelectAppendixModal from './Modals/SelectAppendixModal';

// #region styled-components
const Wrapper = styled.section`
    height: 94px;
    margin: 5px -5px;
    position: relative;
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    // border: 1px solid grey;

    &::-webkit-scrollbar {
        display: none; /* 隐藏滚动条 for Chrome, Safari, Edge */
    }
`;

const Thumbnail = styled.picture`
    position: relative;
    display: inline-block;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: darkblue;
    }

    background-color: ${(props) => (props.active ? 'darkblue' : '')};
`;

const ThumbnailImage = styled.img`
    width: 84px;
    height: 84px;
    object-fit: cover;
`;

const ChangeThumbnail = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    height: 40px;
    width: 20px;
    padding: 0;
    z-index: 9487;
`;

const PrevButton = styled(ChangeThumbnail)`
    left: 0;
    margin-left: 5px;
    z-index: 1;
`;

const NextButton = styled(ChangeThumbnail)`
    right: 0;
    margin-right: 5px;
    z-index: 1;
`;

const SelectFromText = styled.div`
    cursor: pointer;
    font-size: 12px;
`;
//#endregion

// 商品縮略圖區域組件 ThumbnailsSection

// 接受的 props:
// - `appendixList`: 商品附件列表的狀態
// - `setAppendixList`: 用於更新商品附件列表的函數
// - `activeThumbnailGuid`: 當前選中的縮略圖的商品附件 Guid
// - `setActiveThumbnailGuid`: 用於更新選中的縮略圖商品附件 Guid 的函數
// - `data`: 用於商品數據的狀態
// - `setData`: 用於更新商品數據的函數
// - `isAllSpecHaveValue`: 用於檢查是否所有商品規格都有值的標誌
// - `selectedSpec`: 選中的商品規格的狀態
// - `page`: 當前所處頁面的狀態
// - `salesKitSetData`: 商品套件數據的狀態
// - `setSalesKitSetData`: 用於更新商品套件數據的函數
// - `selectedSetGuid`: 選中的商品套件的 Guid
// - `isPreview`: 是否處於預覽模式的標誌

// 使用 useRef 來創建一個文件輸入的引用。
// 使用 useState 來維護當前顯示縮略圖的起始索引。
// 使用 useState 來控制商品附件的選擇模態框是否開啟。

// 定義了處理前一個和後一個縮略圖的函數，以及處理刪除商品附件的函數。
// - `handlePrevThumbnail`: 處理前一個縮略圖的函數。
// - `handleNextThumbnail`: 處理後一個縮略圖的函數。
// - `handleDeleteAppendix`: 處理刪除商品附件的函數。
// - `handleDeleteSalesKitAppendix`: 處理刪除商品套件的商品附件的函數。
// - `handleDeleteSalesKitSetAppendix`: 處理刪除商品套件的函數。

// 渲染商品縮略圖的區域，根據 `startIndex` 控制顯示的縮略圖範圍，最多顯示 5 張。
// 各個縮略圖上提供了選擇、刪除和預覽的功能。

// 如果商品規格都有值且不處於預覽模式，提供一個從商品圖庫中選擇的按鈕，點擊時打開商品附件選擇模態框。

// 商品附件選擇模態框的顯示與隱藏由 `selectAppendixModalOpen` 控制。

// 商品附件選擇模態框組件 `SelectAppendixModal` 的呼叫，根據需要進行商品附件和商品套件數據的更新。

// 該組件也包含一個文件輸入區域，該區域目前被註釋，看起來是用於文件上傳的功能，但被註釋掉了。

// 注意：Icn_chevronLeft、Icn_chevronRight、AddAppendix、SelectFromText 這些組件的具體實現未提供，需要參考這些組件的具體實現。

export default function ThumbnailsSection({
    appendixList,
    setAppendixList,
    activeThumbnailGuid,
    setActiveThumbnailGuid,
    data,
    setData,
    isAllSpecHaveValue = true,
    selectedSpec,
    page,
    salesKitSetData,
    setSalesKitSetData,
    selectedSetGuid,
    isPreview,
}) {
    const fileInputRef = useRef(null);
    const [startIndex, setStartIndex] = useState(0);
    const [selectAppendixModalOpen, setSelectAppendixModalOpen] = useState(false);

    const handlePrevThumbnail = () => {
        const newStartIndex = Math.max(0, startIndex - 1);
        setStartIndex(newStartIndex);
    };

    const handleNextThumbnail = () => {
        const newStartIndex = Math.min(appendixList.length - 5, startIndex + 1);
        setStartIndex(newStartIndex);
    };

    const handleDeleteAppendix = (guid) => {
        setActiveThumbnailGuid(null);

        page === 2 && handleDeleteSalesKitAppendix(guid);
        page === 3 && handleDeleteSalesKitSetAppendix(guid);
    };

    const handleDeleteSalesKitAppendix = (guid) => {
        // 找到要刪除的附件物件的索引
        const index = appendixList.findIndex((item) => item.appendixGuid === guid);

        // 如果找到要刪除的附件
        if (index !== -1) {
            // 創建一個新的附件列表，不包含要刪除的附件
            const updatedList = [...appendixList];

            // 更新附件的 action 屬性
            if (updatedList[index].action === 'origin') {
                updatedList[index].action = 'delete';
            } else {
                // 如果不是 'origin'，直接在新陣列上進行刪除
                updatedList.splice(index, 1);
            }

            console.log(updatedList);

            // 更新狀態（假設在 React 中使用狀態管理）
            setAppendixList(updatedList);
        }
        // 如果沒找到要刪除的附件，你可能想要處理一些錯誤情況
        else {
            console.log('未找到要刪除的附件');
        }
    };

    const handleDeleteSalesKitSetAppendix = (guid) => {
        const index = appendixList.findIndex((item) => item.appendixGuid === guid);

        // 如果找到要刪除的附件
        if (index !== -1) {
            // 創建一個新的附件列表，不包含要刪除的附件
            const updatedList = [...appendixList];

            // 更新附件的 action 屬性
            if (updatedList[index].action === 'origin') {
                updatedList[index].action = 'delete';
            } else {
                // 如果不是 'origin'，直接在新陣列上進行刪除
                updatedList.splice(index, 1);
            }

            console.log(updatedList);

            // 更新狀態（假設在 React 中使用狀態管理）
            setAppendixList(updatedList);
            setSalesKitSetData({
                ...salesKitSetData,
                [selectedSetGuid]: updatedList,
            });
        }
        // 如果沒找到要刪除的附件，你可能想要處理一些錯誤情況
        else {
            console.log('未找到要刪除的附件');
        }
    };

    return (
        <>
            <Wrapper>
                {startIndex > 0 && (
                    <PrevButton onClick={handlePrevThumbnail}>
                        <Icn_chevronLeft />
                    </PrevButton>
                )}
                {appendixList
                    ?.slice(startIndex, startIndex + 5)
                    // ?.filter((el) => el.action !== 'delete')
                    ?.map((item, index) => {
                        return (
                            <div className='relative'>
                                <Thumbnail
                                    key={index}
                                    active={item.appendixGuid === activeThumbnailGuid}
                                    onClick={(e) =>
                                        e.target.tagName === 'IMG' && setActiveThumbnailGuid(item.appendixGuid)
                                    }
                                >
                                    {item.appendixGuid === activeThumbnailGuid && !isPreview && (
                                        <>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '8px',
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDeleteAppendix(activeThumbnailGuid);
                                                }}
                                            >
                                                <svg
                                                    id='Group_6556'
                                                    dataname='Group 6556'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                                    width='16'
                                                    height='16'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <defs>
                                                        <clipPath id='clip-path-times1811DDDDDDD'>
                                                            <rect
                                                                id='Rectangle_3429'
                                                                dataname='Rectangle 3429'
                                                                width='16'
                                                                height='16'
                                                                fill='#ea4132'
                                                            ></rect>
                                                        </clipPath>
                                                    </defs>
                                                    <g
                                                        id='Group_6556-2'
                                                        dataname='Group 6556'
                                                        clipPath='url(#clip-pathtimes1811DDDDDDD)'
                                                    >
                                                        <path
                                                            id='Path_1484'
                                                            dataname='Path 1484'
                                                            d='M6.785,12.011h0a5.226,5.226,0,1,1,5.226-5.226,5.226,5.226,0,0,1-5.226,5.226'
                                                            transform='translate(1.215 1.216)'
                                                            fill='#fff'
                                                        ></path>
                                                        <path
                                                            id='Path_1485'
                                                            dataname='Path 1485'
                                                            d='M8,16a7.995,7.995,0,1,1,5.657-2.34A8,8,0,0,1,8,16M8,9.126H8L10.868,12,12,10.87,9.124,8,12,5.128,10.868,4,8,6.872,5.127,4,4,5.128,6.869,8,4,10.87,5.127,12Z'
                                                            transform='translate(0.001 0.001)'
                                                            fill='#ea4132'
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </>
                                    )}
                                    <source srcSet={item.appendixURL} />
                                    <ThumbnailImage src={item.appendixURL} alt={item.appendixURL} />
                                </Thumbnail>
                            </div>
                        );
                    })}
                {startIndex < appendixList?.filter((el) => el.action !== 'delete').length - 5 && (
                    <NextButton onClick={handleNextThumbnail}>
                        <Icn_chevronRight />
                    </NextButton>
                )}
            </Wrapper>
            {isAllSpecHaveValue && !isPreview && (
                <SelectFromText
                    className='text-primary ms-auto me-2 text-decoration-underline'
                    onClick={() => setSelectAppendixModalOpen(true)}
                >
                    從商品圖庫中選擇
                </SelectFromText>
            )}

            {selectAppendixModalOpen && (
                <SelectAppendixModal
                    modalOpen={selectAppendixModalOpen}
                    setModalOpen={setSelectAppendixModalOpen}
                    appendixList={appendixList}
                    setAppendixList={setAppendixList}
                    activeThumbnailGuid={activeThumbnailGuid}
                    setActiveThumbnailGuid={setActiveThumbnailGuid}
                    data={data}
                    setData={setData}
                    selectedSpec={selectedSpec}
                    page={page}
                    salesKitSetData={salesKitSetData}
                    setSalesKitSetData={setSalesKitSetData}
                    selectedSetGuid={selectedSetGuid}
                />
            )}
            {/* <div
                style={{
                    background: '#EFF2F5',
                    width: '84px',
                    height: '84px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    cursor: 'pointer',
                }}
                onClick={() => fileInputRef.current.click()}
            >
                <input
                    key={appendixList?.length}
                    type="file"
                    style={{
                        display: 'none',
                    }}
                    ref={fileInputRef}
                    accept=".jpg, .png"
                    onChange={(e) => handleFileUpload(e)}
                />
                <AddAppendix />
            </div> */}
        </>
    );
}
