import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

const Wrapper = styled.div`
    font-size: 14px;
`;

const Title = styled.div`
    font-size: 16px;
    color: #989898;
`;

export default function OrderPaymentDetails({ order, t }) {
    const { paymentMethod, paymentStatus, grossAmount, orderDate } = order;

    const paymentDueDate = dayjs(orderDate).add(48, 'h').format('YYYY-MM-DD HH:mm:ss');

    const paymentStatusList = {
        0: t('not_paid'),
        1: t('complete_payment'),
        2: t('partial_payment'),
    };

    const paymentMethodList = {
        0: t('credit_card_payment'),
        1: t('cash_payment'),
        2: t('remittance'),
    };

    return (
        <Wrapper>
            <div className='mt-2'>
                <section className='border-sm-0 border'>
                    <div className='p-4'>
                        <Title>{t('payment_information')}</Title>
                        <div className='mt-4'>
                            <div>{`${t('payment_method')} : ${paymentMethodList[paymentMethod]}`}</div>
                            {paymentMethod === '2' && paymentStatus === '0' && (
                                <>
                                    <div>{`${t('bank_code')} : 006`}</div>
                                    <div>{`${t('receiver_account')} : 5089-898-100889`}</div>
                                    <div>{`${t('payment_deadline')} : ${t('before_payment_due_date', {
                                        dueDate: paymentDueDate,
                                    })}`}</div>
                                </>
                            )}
                            <div>{`${t('payment_amount')} : NT$${grossAmount}`}</div>
                            <div>{`${t('payment_status')} : ${paymentStatusList[paymentStatus]}`}</div>
                        </div>
                    </div>
                </section>
            </div>
        </Wrapper>
    );
}
