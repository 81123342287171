import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

const CancelSelect = styled.select`
    background-color: #f8f8f8;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    border: none;
    border-radius: 4px;
`;

const CancelOption = styled.option``;

const Textarea = styled.textarea`
    resize: none; /* 禁止使用者調整大小 */
    height: 107px;
    width: 100%;
    background-color: #f8f8f8;
    border: none;
    border-radius: 4px;
    outline: none; /* 移除焦點時的輪廓線 */
    padding: 16px;
`;

const RoundedButton = styled.button`
    border-radius: 24px;
    height: 48px;

    @media only screen and (max-width: 1020px) {
        width: 100%;
    }
`;

export default function CancelOrderModal({ isOpen, handleClose, orderNo, orderGuid, handleCancelOrder }) {
    const [cancelReq, setCancelReq] = useState({
        reason: '',
        desc: '',
    });

    console.log(cancelReq);
    return (
        <div key='advancedSearchModal'>
            <Modal isOpen={isOpen} centered={true} style={{ maxWidth: '496px' }}>
                <ModalHeader className='position-relative pt-4 pb-2 ps-4 text-center row'>
                    取消訂單
                    <button
                        style={{ top: '12px', right: '12px' }}
                        type='button'
                        className='close position-absolute btn'
                        onClick={handleClose}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            id='icn_Clear'
                            viewBox='0 0 16 16'
                            width='16'
                            height='16'
                        >
                            <defs>
                                <clipPath id='clipPath-clear22'>
                                    <rect
                                        id='Rectangle_3088'
                                        fill='#686868'
                                        width='10'
                                        height='10'
                                        data-name='Rectangle 3088'
                                    />
                                </clipPath>
                            </defs>
                            <rect id='Rectangle_3086' fill='none' width='16' height='16' data-name='Rectangle 3086' />
                            <g id='Group_6190' transform='translate(3 3)' data-name='Group 6190'>
                                <g id='Group_6188' clipPath='url("#clipPath")' data-name='Group 6188'>
                                    <path
                                        id='Path_1410'
                                        fill='#686868'
                                        transform='translate(0)'
                                        d='M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z'
                                        data-name='Path 1410'
                                    />
                                </g>
                            </g>
                        </svg>
                    </button>
                </ModalHeader>

                <ModalBody className='px-4 pt-3 pb-0'>
                    <div className='mb-4 mt-2'>{`訂單編號 : ${orderNo}`}</div>
                    <div className='mb-4 mt-2'>
                        <div className='mb-2'>取消原因 (必填)</div>
                        <CancelSelect
                            value={cancelReq?.reason}
                            onChange={(e) =>
                                setCancelReq({
                                    ...cancelReq,
                                    reason: e.target.value,
                                })
                            }
                        >
                            <option value='1'>單</option>
                            <option value='2'>純</option>
                            <option value='3'>不</option>
                            <option value='4'>爽</option>
                            <option value='5'>買</option>
                            <option value='6'>單純</option>
                            <option value='7'>純不</option>
                            <option value='8'>不爽</option>
                            <option value='9'>爽買</option>
                            <option value='10'>單純不</option>
                            <option value='11'>純不爽</option>
                            <option value='12'>不爽買</option>
                            <option value='13'>單純不爽</option>
                            <option value='14'>純不爽買</option>
                            <option value='15'>單純不爽買</option>
                        </CancelSelect>
                    </div>
                    <div className='mt-2'>
                        <div className='mb-2'>取消說明</div>
                        <Textarea
                            onChange={(e) =>
                                setCancelReq({
                                    ...cancelReq,
                                    desc: e.target.value,
                                })
                            }
                            value={cancelReq?.desc}
                        ></Textarea>
                    </div>
                </ModalBody>

                <ModalFooter className='modal-footer justify-content-center border-0 px-4 pt-5 pb-5 pb-0'>
                    <RoundedButton
                        className='btn btn-primary w-100 text-white'
                        onClick={() => handleCancelOrder(orderGuid)}
                    >
                        送出申請
                    </RoundedButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}
