import './bldc.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

function Bldc() {
    const [motorNoListDatabase, setMotorNoListDatabase] = useState('');
    const [motorNoListNew, setMotorNoListNew] = useState([]);

    // true false array 用以讓checkbox判斷
    const [checkedState, setCheckedState] = useState([]); // [false, false ...]
    const [isCheckedAll, setIsCheckedAll] = useState(false);

    // 讀取中
    const [isGetMotorNoList, setIsGetMotorNoList] = useState(true);

    // 獲取預設日期為今天
    let currentDate = new Date();
    let today = currentDate.toISOString().substr(0, 10);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    // const yesterdayDate = new Date();
    // yesterdayDate.setDate(currentDate.getDate() - 1);
    // let yesterday = yesterdayDate.toISOString().substr(0, 10);

    // 獲取資料庫馬達編號
    useEffect(() => {
        const getBLDCMotorNoList = async () => {
            const res = await axios.get('web/sys/getbldcmotornolist');

            setMotorNoListDatabase(res.data.motorNoList);
            // 預設全部狀態不勾選
            setCheckedState(new Array(res.data.motorNoList.length).fill(false));
        };

        getBLDCMotorNoList();
    }, []);
    // console.log(motorNoListDatabase);

    // checkbox onchange event
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));

        setCheckedState(updatedCheckedState);

        // 透過true false array 給motorNoListNew選取的馬達value
        const totalItem = updatedCheckedState.map((item, index) => {
            if (item === true) {
                return motorNoListDatabase[index];
            } else {
                return '';
            }
        });

        setMotorNoListNew(totalItem);
        // console.log(totalItem);
        // console.log(motorNoListNew);
    };

    // console.log(startDate, endDate);
    // console.log(checkedState);
    // console.log(motorNoListNew);

    // 全選判斷
    const handleCheckAllMotorNo = () => {
        let ele = document.querySelectorAll('.motorNo');

        const setTotalItem = (boolean) => {
            for (let i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox') ele[i].checked = boolean;
            }

            const totalItem = Array(motorNoListDatabase.length)
                .fill(boolean)
                .map((item, index) => {
                    if (item === true) {
                        return motorNoListDatabase[index];
                    } else {
                        return '';
                    }
                });

            setCheckedState(Array(motorNoListDatabase.length).fill(boolean));
            setMotorNoListNew(totalItem);

            setIsCheckedAll(boolean);
        };

        isCheckedAll === false ? setTotalItem(true) : setTotalItem(false);

        // console.log(checkedState);
        // console.log(motorNoListNew);
    };

    // sweet alert
    const dateError = () => {
        Swal.fire({
            title: '起始日期不能大於結束日期',
            icon: 'warning',
            confirmButtonText: '確定',
        });
    };

    const motorNoError = () => {
        Swal.fire({
            title: '請至少選一個馬達編號',
            icon: 'warning',
            confirmButtonText: '確定',
        });
    };

    // 計時器
    // 初始化變數
    let hour, minute, second;
    //時 分 秒
    hour = minute = second = 0;
    //初始化
    let millisecond = 0;
    //毫秒
    let int;
    //重置函式
    function resetTimer() {
        window.clearInterval(int);
        millisecond = hour = minute = second = 0;
        document.getElementById('timetext').value = '00時00分00秒000毫秒';
    }
    //開始函式
    function startTimer() {
        int = setInterval(timer, 50);
        //每隔50毫秒執行一次timer函式
    }
    //計時函式
    function timer() {
        millisecond = millisecond + 50;
        if (millisecond >= 1000) {
            millisecond = 0;
            second = second + 1;
        }
        if (second >= 60) {
            second = 0;
            minute = minute + 1;
        }
        if (minute >= 60) {
            minute = 0;
            hour = hour + 1;
        }

        document.getElementById('timetext').value = hour + '時' + minute + '分' + second + '秒';
    }
    //暫停函式
    // function stopTimer() {
    // 	window.clearInterval(int);
    // }

    // excel匯出事件
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(startDate, endDate);
        // console.log(checkedState);
        // console.log(motorNoListNew);

        // 過濾未選取的motor
        const motorNoListNewFilter = motorNoListNew.filter((motor) => motor.length > 0);
        // console.log(motorNoListNewFilter);

        const BLDCDPostData = {
            startDate,
            endDate,
            motorNoList: motorNoListNewFilter,
        };

        try {
            if (startDate > endDate) {
                dateError();
            } else if (
                // 至少選一個馬達編號
                document.querySelectorAll('input[type="checkbox"]:checked').length === 0
            ) {
                motorNoError();
            } else {
                // 讀取圖示
                startTimer();
                setIsGetMotorNoList(false);

                const res = await axios.post('/web/sys/exportbldctestingdata', BLDCDPostData, {
                    responseType: 'blob',
                });

                // 獲取後端檔案名
                const fileName = res.request.getResponseHeader('Content-Disposition').split('"')[1];

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                resetTimer();
                setIsGetMotorNoList(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='container-fluid p-0'>
            <article
                id='container-bldc'
                className='py-5 d-flex flex-column align-items-center justify-content-lg-center bg-lg-background '
            >
                <div className='articleBox w-100'>
                    <h2 className='pageTitle'>BLDC 檢測資料</h2>
                    <div className='row'>
                        <Link className='col-1 btn btn-outline-info switch__page-btn' to='/stp'>
                            STP
                        </Link>
                        <Link className='col-1 btn btn-outline-info switch__page-btn' to='/fan'>
                            FAN
                        </Link>
                    </div>
                    <section id='article-bldc'>
                        <form className='row g-3' onSubmit={handleSubmit}>
                            {/* 起始日期 */}
                            <div className='col-6'>
                                <label className='form-label' htmlFor='startDate'>
                                    起始日期
                                </label>
                                <input
                                    type='date'
                                    className='form-control form-control-lg'
                                    id='startDate'
                                    name='startDate'
                                    placeholder='起始日期'
                                    required
                                    onChange={(e) => setStartDate(e.target.value)}
                                    defaultValue={today}
                                />
                                <div className='invalid-feedback' id='startDate-info'></div>
                            </div>

                            {/* 結束日期 */}
                            <div className='col-6'>
                                <label className='form-label' htmlFor='endDate'>
                                    結束日期
                                </label>
                                <input
                                    type='date'
                                    className='form-control form-control-lg'
                                    id='endDate'
                                    name='endDate'
                                    placeholder='結束日期'
                                    required
                                    onChange={(e) => setEndDate(e.target.value)}
                                    defaultValue={today}
                                />
                                <div className='invalid-feedback' id='endDate-info'></div>
                            </div>
                            <div className='col-12 mt-5'>
                                <span>馬達編號</span>
                                <div className='articleInnerBox mt-2'>
                                    {/*讀取畫面*/}
                                    <div
                                        className={`col-12 mt-5 text-center loadingBox ${isGetMotorNoList && 'd-none'}`}
                                        id='loadingBox'
                                    >
                                        <div className='spinner-border text-primary' role='status'></div>
                                    </div>
                                    {/*計時器*/}
                                    <div className={`col-12 mt-5 text-center  ${isGetMotorNoList && 'd-none'}`}>
                                        <input
                                            className='border-0 fs-3 text-center'
                                            type='text'
                                            id='timetext'
                                            value='00時00分00秒'
                                            readOnly
                                        />
                                    </div>
                                    {/* 全選/取消全選 */}
                                    <div className={`px-3 ${!isGetMotorNoList && 'd-none'}`}>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-color-main'
                                            id='checkAllMotorNo'
                                            onClick={() => handleCheckAllMotorNo()}
                                        >
                                            全選 / 取消全選
                                        </button>
                                    </div>
                                    {/* motorNoList */}
                                    <ul
                                        className={`toppings-list list-unstyled d-flex flex-wrap ${
                                            !isGetMotorNoList && 'd-none'
                                        }`}
                                    >
                                        {motorNoListDatabase !== '' &&
                                            motorNoListDatabase.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className='toppings-list-item p-3 d-flex align-items-center'>
                                                            <input
                                                                type='checkbox'
                                                                className='me-1 motorNo'
                                                                id={`custom-checkbox-${index}`}
                                                                name={item}
                                                                value={item}
                                                                onChange={() => handleOnChange(index)}
                                                            />
                                                            <label htmlFor={`custom-checkbox-${index}`}>{item}</label>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </div>

                            {/* 匯出EXCEL */}
                            <div className='col-12 text-center mt-5'>
                                <button
                                    type='submit'
                                    className='w-25 btn btn-lg btn-color-main rounded-pill'
                                    disabled={!isGetMotorNoList}
                                >
                                    匯出EXCEL
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            </article>
        </div>
    );
}

export default Bldc;
