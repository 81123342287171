import './layoutAccount.scss';
import NavMenu from '../navMenu/NavMenu';
import FooterApp from '../footerApp/FooterApp';
import React, { useState } from 'react';
import Sidebar from '../sidebar/Sidebar';

// 自定義sidebar高度
export const SidebarHeightContext = React.createContext();

function LayoutAccount({ children }) {
    const [sidebarHeight, setSidebarHeight] = useState('');

    return (
        <>
            <NavMenu />
            <main id='main-app'>
                <div className='container-custom'>
                    <div className='row-custom'>
                        <SidebarHeightContext.Provider value={{ sidebarHeight, setSidebarHeight }}>
                            <div className='col-m' id='col-sidebar'>
                                <section id='section-sidebar'>
                                    <Sidebar />
                                </section>
                            </div>
                            <div className='col-m' id='col-main'>
                                <section id='section-main'>{children}</section>
                            </div>
                        </SidebarHeightContext.Provider>
                    </div>
                </div>
            </main>
            <FooterApp />
        </>
    );
}

export default LayoutAccount;
