import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_Cancel } from '../../../../assets/images/icn_Cancel.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
`;

const ModalDialog = styled.div`
    max-width: 448px !important;
    min-height: calc(100% - 10.5rem);
`;

// #endregion

export const DialogConfirm = ({ dialogConfirmData, setDialogConfirmData }) => {
    const { t } = useTranslation(['common', 'lgsBuyOrderGoodsReturn']);

    /**
     * Modal儲存
     **/
    function modalSave() {
        if (dialogConfirmData.goTo === 'delete') {
            dialogConfirmData.toDo();
        }

        handleClose();
    }

    /**
     * Modal cancel close
     **/
    const handleClose = () => {
        const newData = {
            btnName: '',
            isOpen: false,
            goTo: '',
            title: '',
        };
        setDialogConfirmData(newData);
    };

    return (
        <>
            {dialogConfirmData.isOpen && (
                <ModalWrapper onClick={handleClose}>
                    <ModalDialog
                        className='modal-dialog d-flex align-items-center'
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            className='modal-content border-0'
                            style={{
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                borderRadius: '12px',
                            }}
                        >
                            <div className='modal-header border-0 px-4 pt-5 pb-4'>
                                <h6 className='w-100 text-center text-danger fw-bold fontSize1Rem mb-0'>
                                    {dialogConfirmData.title}
                                </h6>
                                <button
                                    style={{ top: '12px', right: '12px' }}
                                    type='button'
                                    className='btn close position-absolute '
                                    data-dismiss='modal'
                                    aria-label='Close'
                                    onClick={handleClose}
                                >
                                    <Icn_Cancel />
                                </button>
                            </div>

                            {/* <div className="modal-body text-center px-11 py-0">
								<h6 className="mb-2 fontSize875Rem">
									{t('lgsBuyOrderGoodsReturn:reminder')}！
								</h6>
								<h6 className="mb-0 fontSize875Rem">
									{t(
										'lgsBuyOrderGoodsReturn:dataWillNotBeSavedAfterLeavingPage'
									)}
									。
								</h6>
							</div> */}

                            <div className='modal-footer justify-content-center border-0 py-5'>
                                <button
                                    type='button'
                                    className='btn btn-outline-primary fontSize875Rem d-flex align-items-center justify-content-center mx-1'
                                    style={{
                                        width: '120px',
                                        height: '32px',
                                    }}
                                    onClick={handleClose}
                                >
                                    {t('common:button.cancelButton')}
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary fontSize875Rem text-white d-flex align-items-center justify-content-center mx-1'
                                    style={{
                                        width: '120px',
                                        height: '32px',
                                    }}
                                    onClick={modalSave}
                                >
                                    {t('common:button.confirmButton')}
                                </button>
                            </div>
                        </div>
                    </ModalDialog>
                </ModalWrapper>
            )}
        </>
    );
};
